/* 
Program Name : EditChangeInitiation.jsx
Written By: Snehal Kurhade
Written on:16 MAR 2022 
System Name: Change Management App
Purpose:  Screen to Update details of Change Initiation
Type of Program: React Frontend
*/

/* 
Program Name : EditChangeInitiation.jsx
Defect No : Defect - 8183
Amended By: Vikas Katkar
Amended on: 08 June 2023
System Name: Change Management App
Purpose: Display approval dates when stopwork is approved by DMC & PDA Head in frontend & added check for checking condition stopwork is approved. 
Type of Program: React Frontend
*/

import React, { useState, useEffect, useRef, Component } from "react";
import { Table, Button, Col, Container, Form, Row, Stack } from "react-bootstrap";
import { BrowserRouter as Router, Link, Route, Routes, useLinkClickHandler } from 'react-router-dom'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ComboBox from 'react-responsive-combo-box'
import 'react-responsive-combo-box/dist/index.css'
import $ from 'jquery';
import "../App_Arun.css";
import BootstrapTable from "react-bootstrap-table-next";
import { FileEarmarkText, Trash } from "react-bootstrap-icons";
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import {
    getPdaSpocName,
    getActivePdaSpocName,
} from "../services/PDASpoc-service";

import {
    getdetails,
    UpdateChangeInitiation,
    getSelectedProjectRevisionNo,
    getSelectedProjectTimeandCost,
} from "../services/ChangeInitiation-service";
import {
    getOtherDiv,
    getOtherDivEmail,
} from "../services/OtherDivisions-service";
import {
    getFinanceTeam,
} from "../services/finance-service";

import {
    getHistoryId,
    AddHistoryRecords,
} from "../services/EditChangeInitiation-service";

import {
    getAll,
    getSectionEmail,
} from "../services/SectionMaster-service";

import {
    getEmailId,
} from "../services/PDASpoc-service";

import {
    getWorkPackage,
} from "../services/WorkPackage-service";


//const axios = require("axios").default;

const API_URL = process.env.REACT_APP_CHANGEINITIATION_API_URL + "ChangeInitiation"; // process.env.REACT_APP_CHANGEINITIATION_API_URL;
const EDITAPI_URL = process.env.REACT_APP_CHANGEINITIATION_API_URL + "EditChangeInitiation";
export default function EditChangeInitiation() {
    var ChangeReqNo = sessionStorage.getItem("ChangeReqNo");
    let FeasibilityCount = sessionStorage.getItem("FeasibilityCount");
    const navigate = useNavigate();
    let query = window.location.href
    let indication = "";
    indication = query.split("?")
    if (indication.length > 1) {
        var NavPath = sessionStorage.getItem("NavPath");
        navigate(NavPath);
        window.location.reload();
    }


    // const username = "Vikas Katkar";
    // const useremail = "vikastr@godrej.com";
    let username = localStorage.getItem('UserName');
    let useremail = localStorage.getItem('UserId');
    useEffect(() => {
        fetchFinanceTeam(); fetchWorkPackage(); fetchPDASpocName(); fetchConstructionSectionTeamOptions(); fetchOtherDivisionsOptions(); fetchOtherDivisionEmailOptions(); fetchDetails();
    }, []);


    const [ChangeInitiatorName, setChangeInitiatorName] = useState("");
    const [ChangeInitiatorEmail, setChangeInitiatorEmail] = useState("");
    const [WorkType, setWorkType] = useState("");
    const [TeamName, setTeamName] = useState("");
    const [ProjectName, setProjectName] = useState("");
    const [ProjectNumber, setProjectNumber] = useState("");
    const [ProjectOwner, setProjectOwner] = useState("");
    const [DMCMemberOwner, setDMCMemberOwner] = useState("");
    const [ChangeInitiationOwner, setChangeInitiationOwner] = useState("");
    const [ChangeInitiationOwnerEmail, setChangeInitiationOwnerEmail] = useState("");
    const [ChangeRequestNumber, setChangeRequestNumber] = useState("");
    const [DMCMember, setDMCMember] = useState("");
    const [WorkPackageOptions, setWorkPackageOptions] = useState([]);
    const [WorkPackage, setWorkPackage] = useState("");
    const [Existing, setExisting] = useState("");
    const [Proposed, setProposed] = useState("");
    const [Attachment, setAttachment] = useState("");
    const [JustificationOfChange, setJustificationOfChange] = useState("");
    const [AssumptionsConstraints, setAssumptionsConstraints] = useState("");
    const [KeyRisks, setKeyRisks] = useState("");
    const [ChangeRequestDate, setChangeRequestDate] = useState("");
    const [ChnageInitiationDetails, setChnageInitiationDetails] = useState([]);
    const [OriginalchangenoAndDate, setOriginalchangenoAndDate] = useState("");
    const [Original_time, setOriginal_time] = useState("");
    const [Original_Cost, setOriginal_Cost] = useState("");
    const [R1_ChangeNoAndDate, setR1_ChangeNoAndDate] = useState("");
    const [R1_Time, setR1_Time] = useState("");
    const [R1_Cost, setR1_Cost] = useState("");
    const [R2_ChangeNoAndDate, setR2_ChangeNoAndDate] = useState("");
    const [R2_Time, setR2_Time] = useState("");
    const [R2_Cost, setR2_Cost] = useState("");
    const [R3_ChangeNoAndDate, setR3_ChangeNoAndDate] = useState("");
    const [R3_Time, setR3_Time] = useState("");
    const [R3_Cost, setR3_Cost] = useState("");
    const [R4_ChangeNoAndDate, setR4_ChangeNoAndDate] = useState("");
    const [R4_Time, setR4_Time] = useState("");
    const [R4_Cost, setR4_Cost] = useState("");
    const [IsStopWork, setIsStopWork] = useState("");
    const [StopWork, setStopWork] = useState("No");
    const [NoofDays, setNoofDays] = useState("");
    //BOC:Uncommented for capturing date in backend by vikas
    const [StopWorkApprovedByDmcOn, setStopWorkApprovedByDmcOn] = useState("");
    const [StopWorkApprovedByPda, setStopWorkApprovedByPda] = useState("");
    //EOC:Uncommented for capturing date in backend by vikas
    const [PDAStopWorkApprover, setPDAStopWorkApprover] = useState("");
    const [StopWorkApprovedByPdaOn, setStopWorkApprovedByPdaOn] = useState("");
    const [ChangeAcceptedbyDMC, setChangeAcceptedbyDMC] = useState("");
    const [ChangeValidatedByPDA, setChangeValidatedByPDA] = useState("");
    const [PDAApprover, setPDAApprover] = useState("");
    const [ChangeApprovedbyGC, setChangeApprovedbyGC] = useState("");
    const [GCApprover, setGCApprover] = useState("");
    const [RemarksforRejection, setRemarksforRejection] = useState("");
    // const [RejectedBy, setRejectedBy] = useState("");
    const [RejectedOn, setRejectedOn] = useState("");
    const [StopWorkFlowTrigger, setStopWorkFlowTrigger] = useState("");
    const [ApprovedDMCDate, setApprovedDMCDate] = useState("");
    const [DMCStatus, setDMCStatus] = useState("");
    const [ApprovedByPDA, setApprovedByPDA] = useState("");
    const [FinanceApprover, setFinanceApprover] = useState("");
    const [TeamDMCApprovedOn, setTeamDMCApprovedOn] = useState("");
    const [TeamPDAApprovedOn, setTeamPDAApprovedOn] = useState("");
    const [TeamBHApprovedOn, setTeamBHApprovedOn] = useState("");
    const [Initiated, setInitiated] = useState("");
    const [FeasibilitySubmissionDate, setFeasibilitySubmissionDate] = useState("");
    const [FeasiblityLastModify, setFeasiblityLastModify] = useState("");
    const [StopWorkApprovedByDMC, setStopWorkApprovedByDMC] = useState("");
    // const [IsStopWorkPendingWithInitiator, setIsStopWorkPendingWithInitiator] = useState("");
    // const [PendingWithInitiator, setPendingWithInitiator] = useState("");
    const [PdastopworkapprovalRemarks, setPdastopworkapprovalRemarks] = useState("");
    const [GCstopworkapprovalRemarks, setGCstopworkapprovalRemarks] = useState("");
    const [StopWorkApprovedByGC, setStopWorkApprovedByGC] = useState("");
    const [GCStopWorkApprover, setGCStopWorkApprover] = useState("");
    // const [GCStopWorkApproveDate, setGCStopWorkApproveDate] = useState("");
    const [ChangeRequestID, setChangeRequestID] = useState("");
    // const [CountOfStakeHolders, setCountOfStakeHolders] = useState("");
    const [ApprovedByDmc, setApprovedByDmc] = useState("");
    const [ApprovedByPDATeam, setApprovedByPDATeam] = useState("");
    const [RemarkByPDA, setRemarkByPDA] = useState("");
    const [RemarkByProjDmc, setRemarkByProjDmc] = useState("");

    const [OriginalCommitment_Time, setOriginalCommitment_Time] = useState("");
    const [OriginalCommitmentCost, setOriginalCommitmentCost] = useState("");
    const [LastApproved_Time, setLastApproved_Time] = useState("");
    const [LastApprovedCost, setLastApprovedCost] = useState("");
    const [NewProposed_time, setNewProposed_time] = useState("");
    const [NewProposed_Cost, setNewProposed_Cost] = useState("");
    const [Impact_EndDate, setImpact_EndDate] = useState("No");
    const [Impact_Budget, setImpact_Budget] = useState("No");
    const [RevisedBudget, setRevisedBudget] = useState("");
    const [RevisedEndDate, setRevisedEndDate] = useState("");

    const [PDASpocName, setPDASpocName] = useState("");
    const [PDASpocEmail, setPDASpocEmail] = useState("");
    const [FinanceMemberNameOptions, setFinanceMemberNameOptions] = useState([]);
    const [FinanceMemberName, setFinanceMemberName] = useState("");
    const [ApprovedByFinance, setApprovedByFinance] = useState("");
    const [RemarkByFinance, setRemarkByFinance] = useState("");
    const [ProjDMCName, setProjDMCName] = useState("");
    const [RemarkByBH, setRemarkByBH] = useState("");
    const [ChangeFesibility_releventStakeholders, setChangeFesibility_releventStakeholders] = useState("");
    const [DMCMemberEmail, setDMCMemberEmail] = useState("");
    const [PDAHeadEmail, setPDAHeadEmail] = useState("");
    const [BHHeadEmail, setBHHeadEmail] = useState("");
    const [RemarkByPdaHead, setRemarkByPdaHead] = useState("");
    const [MaxRevisionNo, setMaxRevisionNo] = useState(0);
    const [RevisionNo, setRevisionNo] = useState(0);
    const [tmpGodrejConstructionSections, settmpGodrejConstructionSections] = useState("");
    const [GodrejConstructionSectionsEmailOptions, setGodrejConstructionSectionsEmailOptions] = useState([]);
    const [tmpGodrejConstructionSectionsEmail, settmpGodrejConstructionSectionsEmail] = useState("");
    const [ConstructionSectionTeamOptions, setConstructionSectionTeamOptions] = useState([]);
    const [SelectedConstructionSection, setSelectedConstructionSection] = useState([]);
    const [FeasibilityReportCount, setFeasibilityReportCount] = useState(0);
    const [Other, setOther] = useState("");//columnname
    const [OtherDivisionOptions, setOtherDivisionOptions] = useState([]);
    const [OtherDivision, setOtherDivision] = useState("");
    const [OtherDivisionEmailOptions, setOtherDivisionEmailOptions] = useState([]);
    const [OtherDivisionEmail, setOtherDivisionEmail] = useState("");
    const [PDASpocNameOptions, setPDASpocNameOptions] = useState([]);
    const [IsFinance, setIsFinance] = useState("");
    const [PDAInitiated, setPDAInitiated] = useState("");
    const [tmpPendingWithInitiator, settmpPendingWithInitiator] = useState("");

    const [ChangeFeasibilityAnnexed, setChangeFeasibilityAnnexed] = useState("");
    // const [GodrejConstructionSections, setGodrejConstructionSections] = useState("");
    // const [GodrejSectionDivisions, setGodrejSectionDivisions] = useState("");
    const [PrevPendingWithInitiator, setPrevPendingWithInitiator] = useState("");
    const [FileLocation, setFileLocation] = useState([]);
    //attachment
    const [UploadedFileLocation, setUploadedFileLocation] = useState("");
    const [MultipleFileLocations, setMultipleFileLocations] = useState([]);
    const [FileNames, setFileNames] = useState([]);

    const [newAttachedFile, setnewAttachedFile] = useState([]);
    const [fileList, setFileList] = useState([]);

    const [CT, setCT] = useState("");
    const [NT, setNT] = useState("");
    const [PT, setPT] = useState("");


    let MaxRevNo = 0;
    let RvsnNo = 0;
    let GodrejConstructionSections = "";
    let GodrejSectionDivisions = "";
    let CountOfStakeHolders = 0;
    // let StopWorkApprovedByDmcOn = "";
    // let StopWorkApprovedByPdaOn = "";
    //BOC Commented by Vikas on 08/06/2023 for date capturing
    // let StopWorkApprovedByDmcOn = "";
    // let StopWorkApprovedByPdaOn = "";
    //EOC Commented by Vikas on 08/06/2023 for date capturing
    let GCStopWorkApproveDate = "";
    let ApprovedByDmcDate = "";
    let ApprovedByPDATeamOn = "";
    let ProjDMCAcceptanceDate = "";
    let ApprovedByFinanceOn = "";
    let ApprovedByPdaOn = "";
    let GCHeadApprovalDate = "";
    let IsRejected = "";
    let currentStage = "";
    let RejectedStage = "";
    let PendingWithInitiator = "";
    let FileLocations = "";
    let FileName = "";
    let IsStopWorkRejected = "";
    let StopworkRejectedStage = "";
    let IsStopWorkPendingWithInitiator = ""
    let Tomail = "";
    let CCMail = "";
    let RejectedBy = "";
    let InvalidSelect = "";
    let RejectionRemarks = "";
    let SwitchChange = "";
    let Modified = "";
    let ModifiedBy = username;

    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    const todaysdate = dd + '/' + mm + '/' + yyyy;
    Modified = todaysdate;
    let NavigationPath = sessionStorage.getItem("NavPath");
    const YesNoOptions = [
        { value: 'Select', label: 'Select' },
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' },

    ];





    const fetchOtherDivisionsOptions = async () => {

        const result = await getOtherDiv();
        let data = result.map(t => t.Division);
        let uniquedata = [...new Set(data)];
        let tempOtherDivision = [];
        tempOtherDivision.push({ Division: "Select" });
        for (let property in uniquedata) {
            tempOtherDivision.push({ Division: uniquedata[property] });
        }
        setOtherDivisionOptions(tempOtherDivision);
        // setOtherDivision(document.getElementById("OtherDivision").value);
        fetchOtherDivisionEmailOptions();
    };
    const fetchWorkPackage = async () => {
        const result = await getWorkPackage();
        setWorkPackageOptions(result);
    };
    const fetchOtherDivisionEmailOptions = async () => {
        const other = "Corporate";
        // const other = document.getElementById("OtherDivision").value.toString();
        const result = await getOtherDivEmail(other);

        let data = result.map(t => t.EmailId);
        let uniquedata = [...new Set(data)];
        let OtherEmail = [];
        OtherEmail.push({ EmailId: "Select" });
        for (let property in uniquedata) {
            OtherEmail.push({ EmailId: uniquedata[property] });
        }
        setOtherDivisionEmailOptions(OtherEmail);


    };
    const onBackClick = () => {
        var NavPath = sessionStorage.getItem("NavPath");
        navigate(NavPath);
    }

    const getOtherDivisionEmailOptions = () => {
        fetchOtherDivisionEmailOptions();
    };
    const fetchPDASpocName = async () => {
        const result = await getActivePdaSpocName("Yes");
        let data = result.map(t => t.TeamMemberName);
        let uniquedata = [...new Set(data)];
        let PDASpocName = [];
        PDASpocName.push({ PDAname: "Select" });
        for (let property in uniquedata) {
            PDASpocName.push({ PDAname: uniquedata[property] });
        }
        setPDASpocNameOptions(PDASpocName);
        // setPDASpocName(document.getElementById("pdaspoc").value);
    };

    const fetchFinanceTeam = async () => {
        const result = await getFinanceTeam();
        let data = result.map(t => t.FName);
        let uniquedata = [...new Set(data)];
        let FinanceName = [];
        FinanceName.push({ Fname: "Select" });
        for (let property in uniquedata) {
            FinanceName.push({ Fname: uniquedata[property] });
        }
        setFinanceMemberNameOptions(FinanceName);
        // setFinanceMemberName(document.getElementById("financeteam").value);

    };

    const fetchDetails = async () => {
        setCT("ChangeInitiation");
        setNT("TimeAndCost");
        setPT("");
        const result = await getdetails(ChangeReqNo);
        setChnageInitiationDetails(result);
        setWorkType((result.map(i => i.WorkType)).toString());
        setTeamName((result.map(i => i.TeamName)).toString());
        setProjectNumber((result.map(i => i.ProjectNumber)).toString());
        setRevisionNo((result.map(i => i.RevisionNo)));


        settmpPendingWithInitiator((result.map(i => i.tmpPendingWithInitiator)).toString());

        // alert((result.map(i => i.tmpPendingWithInitiator)).toString());
        setChangeRequestNumber((result.map(i => i.ChangeRequestNumber)).toString());
        setProjectName((result.map(i => i.ProjectName)).toString());
        setChangeInitiationOwner((result.map(i => i.ChangeInitiationOwner)).toString());
        setChangeInitiationOwnerEmail((result.map(i => i.ChangeInitiationOwnerEmail)).toString());
        setWorkPackage((result.map(i => i.WorkPackage)).toString());
        // document.getElementById("WorkPackage").value=(result.map(i => i.WorkPackage)).toString();
        setExisting((result.map(i => i.Existing)).toString());
        setProposed((result.map(i => i.Proposed)).toString());
        setJustificationOfChange((result.map(i => i.JustificationOfChange)).toString());
        setAssumptionsConstraints((result.map(i => i.AssumptionsConstraints)).toString());
        setKeyRisks((result.map(i => i.KeyRisks)).toString());
        setFileLocation(((result.map(i => i.FileLocations)).toString()));
        const flocation = result.map(i => i.FileLocations).toString().split(",")
        const fname = result.map(i => i.FileName).toString().split(",")
        let s = "";
        // for (let i=0; i<flocation.length;i++) {
        // //    s=" <a href=" + {flocation[i]} + "> Download Here </a>";
        // s +=" <a href=" + flocation[i] + ">" + fname[i] +"</a>";

        // }
        if (fname.length != 0) {
            for (let i = 0; i < fname.length; i++) {
                let fileerror = "";
                const API_ENDPOINT = process.env.REACT_APP_CHANGEINITIATION_API_URL + "Attachment/filename/" + fname[i]
                const response = await axios({
                    method: 'GET',
                    url: API_ENDPOINT
                }).catch((error) => {
                    fileerror = "Yes"
                    console.log(error);
                });
                //    s=" <a href=" + {flocation[i]} + "> Download Here </a>";
                if (fileerror != "Yes") {
                    s += " <a href=" + response.data.uploadURL + ">" + fname[i] + "</a>";
                }

            }
        }
        FileLocations = (result.map(i => i.FileLocations)).toString();
        if (s != "") {
            (document.getElementById("attachmentrow").innerHTML = s)
        }

        setOriginalchangenoAndDate((result.map(i => i.OriginalchangenoAndDate)).toString());
        setOriginal_time(((result.map(i => i.Original_time)).toString()));
        setOriginal_Cost(((result.map(i => i.Original_Cost)).toString()));
        setR1_ChangeNoAndDate(((result.map(i => i.R1_ChangeNoAndDate)).toString()));
        setR1_Time(((result.map(i => i.R1_Time)).toString()));
        setR1_Cost(((result.map(i => i.R1_Cost)).toString()));
        setR2_ChangeNoAndDate(((result.map(i => i.R2_ChangeNoAndDate)).toString()));
        setR2_Time(((result.map(i => i.R2_Time)).toString()));
        setR2_Cost(((result.map(i => i.R2_Cost)).toString()));
        setR3_ChangeNoAndDate(((result.map(i => i.R3_ChangeNoAndDate)).toString()));
        setPrevPendingWithInitiator((result.map(i => i.PendingWithInitiator)).toString());
        setR3_Time(((result.map(i => i.R3_Time)).toString()));
        setR3_Cost(((result.map(i => i.R3_Cost)).toString()));
        setR4_ChangeNoAndDate(((result.map(i => i.R4_ChangeNoAndDate)).toString()));
        setR4_Time(((result.map(i => i.R4_Time)).toString()));
        setR4_Cost(((result.map(i => i.R4_Cost)).toString()));
        setOriginalCommitment_Time(((result.map(i => i.OriginalCommitment_Time)).toString()));
        setOriginalCommitmentCost(((result.map(i => i.OriginalCommitmentCost)).toString()));
        setLastApproved_Time(((result.map(i => i.LastApproved_Time)).toString()));
        setLastApprovedCost(((result.map(i => i.LastApprovedCost)).toString()));
        setNewProposed_time(((result.map(i => i.NewProposed_time)).toString()));
        setNewProposed_Cost(((result.map(i => i.NewProposed_Cost)).toString()));
        setImpact_EndDate(((result.map(i => i.Impact_EndDate)).toString()));
        setImpact_Budget(((result.map(i => i.Impact_Budget)).toString()));
        setRevisedEndDate(((result.map(i => i.RevisedEndDate)).toString()));
        setRevisedBudget(((result.map(i => i.RevisedBudget)).toString()));
        setChangeFesibility_releventStakeholders(((result.map(i => i.ChangeFesibility_releventStakeholders)).toString()));
        setOtherDivision(((result.map(i => i.OtherDivision)).toString()));
        setOtherDivisionEmail(((result.map(i => i.OtherDivisionEmail)).toString()));
        setChangeFeasibilityAnnexed(((result.map(i => i.ChangeFeasibilityAnnexed)).toString()));
        setIsFinance(((result.map(i => i.IsFinance)).toString()));
        setFinanceMemberName(((result.map(i => i.FinanceMemberName)).toString()));
        setStopWork(((result.map(i => i.StopWork)).toString()));
        //BOC by Vikas for date capturing in backend
        setStopWorkApprovedByDmcOn(((result.map(i => i.StopWorkApprovedByDmcOn)).toString()));
        setStopWorkApprovedByPdaOn(((result.map(i => i.StopWorkApprovedByPdaOn)).toString()));
        //EOC by Vikas for date capturing in backend
        if ((result.map(i => i.StopWork)).toString() == "Yes") {
            document.getElementById("stopwork").checked = true;
        }
        else {
            document.getElementById("stopwork").checked = false;
        }

        setPDAInitiated(((result.map(i => i.PDAInitiated)).toString()));
        setIsStopWork(((result.map(i => i.StopWork)).toString()));
        setNoofDays(((result.map(i => i.NoofDays)).toString()));
        setStopWorkApprovedByDMC(((result.map(i => i.StopWorkApprovedByDMC)).toString()));
        setStopWorkApprovedByPda(((result.map(i => i.StopWorkApprovedByPda)).toString()));
        setPDAStopWorkApprover(((result.map(i => i.PDAStopWorkApprover)).toString()));
        setPdastopworkapprovalRemarks(((result.map(i => i.PdastopworkapprovalRemarks)).toString()));
        setGCStopWorkApprover(((result.map(i => i.GCStopWorkApprover)).toString()));
        setStopWorkApprovedByGC(((result.map(i => i.StopWorkApprovedByGC)).toString()));
        setGCstopworkapprovalRemarks(((result.map(i => i.GCstopworkapprovalRemarks)).toString()));
        setDMCMember(((result.map(i => i.DMCMember)).toString()));
        if ((result.map(i => i.ApprovedByDmc)).toString().toUpperCase() == "YES") {
            setApprovedByDmc(((result.map(i => i.ApprovedByDmc)).toString()));
        }
        else {
            setApprovedByDmc("");
        }

        setRemarksforRejection(((result.map(i => i.RemarksforRejection)).toString()));
        setPDASpocName(((result.map(i => i.PDASpocName)).toString()));
        if ((result.map(i => i.ApprovedByPDATeam)).toString().toUpperCase() == "YES") {
            setApprovedByPDATeam(((result.map(i => i.ApprovedByPDATeam)).toString()));
        }
        else {
            setApprovedByPDATeam("");
        }


        setRemarkByPDA(((result.map(i => i.RemarkByPDA)).toString()));
        setFinanceApprover(((result.map(i => i.FinanceApprover)).toString()));
        if ((result.map(i => i.ApprovedByFinance)).toString().toUpperCase() == "YES") {
            setApprovedByFinance(((result.map(i => i.ApprovedByFinance)).toString()));
        }
        else {
            setApprovedByFinance("");
        }

        setRemarkByFinance(((result.map(i => i.RemarkByFinance)).toString()));
        setDMCMemberEmail(((result.map(i => i.DMCMemberEmail)).toString()));
        setDMCMemberOwner(((result.map(i => i.DMCMemberOwner)).toString()));
        if ((result.map(i => i.ChangeAcceptedbyDMC)).toString().toUpperCase() == "YES") {
            setChangeAcceptedbyDMC(((result.map(i => i.ChangeAcceptedbyDMC)).toString()));
        }
        else {
            setChangeAcceptedbyDMC("");
        }

        setRemarkByProjDmc(((result.map(i => i.RemarkByProjDmc)).toString()));
        setPDAApprover(((result.map(i => i.PDAApprover)).toString()));

        if ((result.map(i => i.ChangeValidatedByPDA)).toString().toUpperCase() == "YES") {
            setChangeValidatedByPDA(((result.map(i => i.ChangeValidatedByPDA)).toString()));
        }
        else {
            setChangeValidatedByPDA("");
        }
        setRemarkByPdaHead(((result.map(i => i.RemarkByPdaHead)).toString()));

        if ((result.map(i => i.ChangeApprovedbyGC)).toString().toUpperCase() == "YES") {
            setChangeApprovedbyGC(((result.map(i => i.ChangeApprovedbyGC)).toString()));
        }
        else {
            setChangeApprovedbyGC("");
        }
        setGCApprover(((result.map(i => i.GCApprover)).toString()));
        setRemarkByBH(((result.map(i => i.RemarkByBH)).toString()));
        setPDAHeadEmail(((result.map(i => i.PDAStopWorkApprover)).toString()));
        setBHHeadEmail(((result.map(i => i.GCStopWorkApprover)).toString()));
        setGCStopWorkApprover(((result.map(i => i.GCStopWorkApprover)).toString()));

        settmpGodrejConstructionSections(((result.map(i => i.GodrejSectionDivisions)).toString()));

        const team = (result.map(i => i.GodrejSectionDivisions).toString()).split(",");
        const email = (result.map(i => i.GodrejConstructionSections).toString()).split(",")
        fetchPDASPOCMail();
        if ((result.map(i => i.WorkPackage)).toString() == "") {
            const result = await getWorkPackage();
            setWorkPackageOptions(result);
            setWorkPackage(result[0].WorkPackageDetail);
        }

        setSelectedConstructionSection([]);
        team.forEach((num1, index) => {
            const num2 = email[index];

            SelectedConstructionSection.push({ SectionTeam: num1, SectionEmail: num2 });
        });

        if (((result.map(i => i.ChangeFesibility_releventStakeholders)) == "No") || ((result.map(i => i.CountOfStakeHolders)).toString() == FeasibilityCount.toString())) {
            document.getElementById("TimeCosttarget").style.display = "block";
        }
        else {
            document.getElementById("TimeCosttarget").style.display = "none";
        }


        if ((result.map(i => i.PendingWithInitiator)).toString().toUpperCase() == "YES") {
            document.getElementById("constructionSectionTeamtxt").style.display = "none";
            document.getElementById("constructionSectionTeam").style.display = "block";
            document.getElementById("constructionSectionEmailtxt").style.display = "none";
            document.getElementById("constructionSectionEmail").style.display = "block";
            document.getElementById("sectiontable").style.display = "none";
            document.getElementById("attachmentrow").style.display = "none";
        }
        else {
            document.getElementById("constructionSectionTeamtxt").style.display = "block";
            document.getElementById("constructionSectionTeam").style.display = "none";
            document.getElementById("constructionSectionEmailtxt").style.display = "block";
            document.getElementById("constructionSectionEmail").style.display = "none";
            document.getElementById("sectiontable").style.display = "none";
            document.getElementById("Dropzone").style.display = "none";
        }
        settmpGodrejConstructionSectionsEmail(((result.map(i => i.GodrejConstructionSections)).toString()));
        console.log("ApprovedByDmc ", ApprovedByDmc, " ApprovedByPDATeam ", ApprovedByPDATeam, " ApprovedByFinance ", ApprovedByFinance, " ChangeAcceptedbyDMC ", ChangeAcceptedbyDMC,
            " ChangeValidatedByPDA ", ChangeValidatedByPDA, " ChangeApprovedbyGC ", ChangeApprovedbyGC)


    };


    const fetchConstructionSectionTeamOptions = async () => {

        const result = await getAll();
        let data = result.map(t => t.SMTeamName);
        let uniquedata = [...new Set(data)];
        let ConstructionSectionTeam = [];
        ConstructionSectionTeam.push({ SectionTeam: "Select", SectionTeamValue: "" });
        for (let property in uniquedata) {
            ConstructionSectionTeam.push({ SectionTeam: uniquedata[property], SectionTeamValue: uniquedata[property] });
        }
        setConstructionSectionTeamOptions(ConstructionSectionTeam);
        // settmpGodrejConstructionSections(document.getElementById("constructionSectionTeam").value);
        // fetchSectionEmailOptions();
    };
    const getSectionEmailOptions = () => {
        fetchSectionEmailOptions();
    };
    const fetchSectionEmailOptions = async () => {

        const sectionTeam = document.getElementById("constructionSectionTeam").value;
        const result = await getSectionEmail(sectionTeam);
        let data = result.map(t => t.SMEmailID);
        console.log("data", data);
        let uniquedata = [...new Set(data)];
        let ConstructionSectionEmail = [];
        ConstructionSectionEmail.push({ SMEmailID: "Select", SMEmailIDValue: "" });
        for (let property in uniquedata) {
            ConstructionSectionEmail.push({ SMEmailID: uniquedata[property], SMEmailIDValue: uniquedata[property] });
        }
        setGodrejConstructionSectionsEmailOptions(ConstructionSectionEmail);
    };

    const fetchPDASPOCMail = async () => {
        let pdaspname = document.getElementById("pdaspoc").value;
        const PDASPOCresult = await getEmailId(pdaspname.toString());
        setPDASpocEmail((PDASPOCresult.map(t => t.TeamMemberEmail)).toString());
    };


    const handleChangeStatus = ({ meta, remove }, status) => {

        console.log(status, meta);

    }


    const onImpactTimeSwitchAction = () => {
        if (document.getElementById("sw_Impact_EndDate").checked == true) {
            setImpact_EndDate("Yes");
        }
        else {
            setImpact_EndDate("No");

        }
    };
    const onImpactBudgetSwitchAction = () => {
        if (document.getElementById("sw_Impact_Budget").checked == true) {
            setImpact_Budget("Yes");
        }
        else {
            setImpact_Budget("No");

        }
    };



    const onFileDrop = (e) => {

        const newFile = e.target.files[0];

        const abc = [...newAttachedFile, newFile];
        let tmpAllowedFileType = process.env.REACT_APP_ATTACHMENT_TYPE;
        const AllowedFileType = tmpAllowedFileType.split(",");
        let isvalid = true;
        let isvalidFormat = false;
        let Filetype = "";

        Filetype = newFile.name.slice((Math.max(0, newFile.name.lastIndexOf(".")) || Infinity) + 1)

        for (let j = 0; j < AllowedFileType.length; j++) {
            if (Filetype == AllowedFileType[j]) {
                isvalidFormat = true;
            }
        }

        if (isvalidFormat != true) {
            alert("Attached file should be of type .doc, .pdf, .xls, .xlsx, .mst, .ppt, .dwg \r\n Files are not saved ");
            isvalidFormat = false;
            return;
        }
        if (newFile.size > 5000000) {
            alert("File " + newFile.name + " is greater than 5 MB \r\n Files are not saved");
            isvalid = false;
            return;
        }

        if (isvalid) {
            if (newFile) {
                const updatedList = [...fileList, newFile];
                setFileList(updatedList);
                handleAttachmentSubmit(abc);

                setnewAttachedFile([]);
            }
        }
    };

    const fileRemove = (file) => {
        const updatedList = [...fileList];
        updatedList.splice(fileList.indexOf(file), 1);
        setFileList(updatedList);
        MultipleFileLocations.splice(fileList.indexOf(file), 1);
        FileNames.splice(fileList.indexOf(file), 1);

    };

    const handleAttachmentSubmit = async (files) => {

        let isvalid = true;

        let tmpAllowedFileType = process.env.REACT_APP_ATTACHMENT_TYPE;
        const AllowedFileType = tmpAllowedFileType.split(",");

        for (let i = 0; i < files.length; i++) {
            let isvalidFormat = false;
            const tempf = files[i];

            let Filetype = "";
            Filetype = files[i].name.slice((Math.max(0, files[i].name.lastIndexOf(".")) || Infinity) + 1)

            for (let j = 0; j < AllowedFileType.length; j++) {
                if (Filetype == AllowedFileType[j]) {
                    isvalidFormat = true;
                }
            }

            if (isvalidFormat != true) {
                alert("Attached file should be of type .doc, .pdf, .xls, .xlsx, .mst, .ppt, .dwg \r\n Files are not saved ");
                isvalidFormat = false;
                return;
            }
            if (files[i].size > 5000000) {
                alert("File " + files[i].name + " is greater than 5 MB \r\n Files are not saved");
                isvalid = false;
                return;
            }

        }
        if (isvalid == true) {

            for (let i = 0; i < files.length; i++) {
                const f = files[i];
                FileNames.push({ Fnames: files[i].name });
                const API_ENDPOINT = process.env.REACT_APP_CHANGEINITIATION_API_URL + "Attachment/filename/" + files[i].name

                const response = await axios({
                    method: 'GET',
                    url: API_ENDPOINT
                }).catch((error) => {
                    console.log(error);

                });


                const result = await fetch(response.data.uploadURL, {
                    method: 'PUT',
                    body: files[i]
                }).catch((error) => {
                    console.log(error);

                });

                let var2 = response.data.uploadURL;
                // let s3Location = var2.substring(0, var2.indexOf("?"));
                let s3Location = response.data.uploadURL;

                setUploadedFileLocation(s3Location);
                MultipleFileLocations.push({ s3Location: s3Location });

            }

        }



    }


    const fetchSelectedProjectData = async () => {


        const projnum = document.getElementById("ProjectNumber").value;

        RvsnNo = RevisionNo - 1;

        if (RvsnNo != 0) {
            const TimeandCostResult = await getSelectedProjectTimeandCost(projnum, RvsnNo);

            setChangeRequestDate(((TimeandCostResult.map(i => i.ChangeRequestDate)).toString()));
            setOriginalchangenoAndDate((TimeandCostResult.map(i => i.OriginalchangenoAndDate)).toString());
            setOriginal_time(((TimeandCostResult.map(i => i.Original_time)).toString()));
            setOriginal_Cost(((TimeandCostResult.map(i => i.Original_Cost)).toString()));
            setR1_ChangeNoAndDate(((TimeandCostResult.map(i => i.R1_ChangeNoAndDate)).toString()));

            setR1_Time(((TimeandCostResult.map(i => i.R1_Time)).toString()));

            setR1_Cost(((TimeandCostResult.map(i => i.R1_Cost)).toString()));

            setR2_ChangeNoAndDate(((TimeandCostResult.map(i => i.R2_ChangeNoAndDate)).toString()));

            setR2_Time(((TimeandCostResult.map(i => i.R2_Time)).toString()));

            setR2_Cost(((TimeandCostResult.map(i => i.R2_Cost)).toString()));

            setR3_ChangeNoAndDate(((TimeandCostResult.map(i => i.R3_ChangeNoAndDate)).toString()));

            setR3_Time(((TimeandCostResult.map(i => i.R3_Time)).toString()));

            setR3_Cost(((TimeandCostResult.map(i => i.R3_Cost)).toString()));

            setR4_ChangeNoAndDate(((TimeandCostResult.map(i => i.R4_ChangeNoAndDate)).toString()));

            setR4_Time(((TimeandCostResult.map(i => i.R4_Time)).toString()));

            setR4_Cost(((TimeandCostResult.map(i => i.R4_Cost)).toString()));

            if (RvsnNo == 0) {
                setR1_ChangeNoAndDate(projnum + "-" + (RvsnNo + 1) + "-" + ChangeRequestDate);
            } else if (RvsnNo == 1) {
                setR2_ChangeNoAndDate(projnum + "-" + (RvsnNo + 1) + "-" + ChangeRequestDate);
            } else if (RvsnNo == 2) {
                setR3_ChangeNoAndDate(projnum + "-" + (RvsnNo + 1) + "-" + ChangeRequestDate);
            }
            else if (RvsnNo == 3) {
                setR4_ChangeNoAndDate(projnum + "-" + (RvsnNo + 1) + "-" + ChangeRequestDate);
            }

        }
        else {
            setR1_ChangeNoAndDate((projnum + "-" + (1) + "-" + ChangeRequestDate).toString());
        }
    }

    const ChangeFeasibilityDrpdownChange = () => {

        if (document.getElementById("ChangeFesibility_releventStakeholders").value.toUpperCase() != "YES") {

            while (SelectedConstructionSection.length > 0) {
                SelectedConstructionSection.pop();
            }
            setSelectedConstructionSection([...SelectedConstructionSection])

            settmpGodrejConstructionSections("Select");
            settmpGodrejConstructionSectionsEmail("Select");
            document.getElementById("constructionSectionTeam").value = "Select";
            document.getElementById("constructionSectionEmail").value = "Select";

        }

    };
    const chanegEvent = (e) => {
        switch (CT) {
            case "ChangeInitiation":
                setProjectName(document.getElementById("ProjectName").value);
                setProjectNumber(document.getElementById("ProjectNumber").value);
                setDMCMemberOwner(document.getElementById("DMCMemberOwner").value);
                setTeamName(document.getElementById("TeamName").value);

                var alertMessage = "";
                if (ProjectName.trim() == "") { alertMessage += "Please Select Project Name \r\n"; }
                if (WorkType.trim() == "") { alertMessage += "Please Select Work Type \r\n"; }
                if (TeamName.trim() == "") { alertMessage += "Please Select TeamName \r\n"; }
                if (ProjectNumber.trim() == "") { alertMessage += "Please Select Project Number \r\n"; }
                if (DMCMemberOwner.trim() == "") { alertMessage += "Please Select Project DMC \r\n"; }
              
                if (DMCMember.trim() == "") { alertMessage += "DMC Member (Seeking Change) is Empty\r\n"; }
              
                if (GCApprover == "" || GCApprover == null || GCApprover == undefined) { alertMessage += "Business Head Name is Empty\r\n "; }
                if (PDAApprover == "" || PDAApprover == null || PDAApprover == undefined) { alertMessage += "PDA Head Name is Empty\r\n "; }
                if (WorkPackage.trim() == "") { alertMessage += "Please Select Work Package \r\n"; }
                if (Existing.trim() == "") { alertMessage += "Please Enter Existing Field\r\n"; }
                if (Proposed.trim() == "") { alertMessage += "Please Enter Proposed Field \r\n"; }
                if (JustificationOfChange.trim() == "") { alertMessage += "Please Enter Justification of Change Field \r\n"; }
                if (AssumptionsConstraints.trim() == "") { alertMessage += "Please Enter Assumptions Constraints Field \r\n"; }
                if (KeyRisks.trim() == "") { alertMessage += "Please Enter Key Risks Field \r\n"; }
                if (alertMessage.trim() != "") {
                    alert(alertMessage);
                    SetEventTab(CT);
                    return;
                }
                else {
                    SetEventTab(e);
                    setCT(e);
                    setNT("ChangeApproval1");
                    setPT("ChangeInitiation");
                    if (PrevPendingWithInitiator.toString().toUpperCase() == "YES" && PDAInitiated.toString().toUpperCase() == "YES") {

                        fetchSelectedProjectData();
                    }

                }

                break;
            case "TimeAndCost":

                var alertMessage = "";
                if (document.getElementById("Original_time").disabled == false && Original_time.trim() == "") { alertMessage += "Please Enter Original Time \r\n"; }

                if (document.getElementById("Original_Cost").disabled == false && Original_Cost.trim() == "") { alertMessage += "Please Enter Original Cost \r\n"; }
                if (Math.sign(Original_Cost) == -1) { alertMessage += "Please Enter Positive value of Original Cost \r\n"; }

                if (document.getElementById("R1_Time").disabled == false && R1_Time.trim() == "") { alertMessage += "Please Enter R1 Time \r\n"; }

                if (document.getElementById("R1_Cost").disabled == false && R1_Cost.trim() == "") { alertMessage += "Please Enter R1 Cost \r\n"; }

                if (Math.sign(R1_Cost) == -1) { alertMessage += "Please Enter Positive value of R1 Cost \r\n"; }

                if (document.getElementById("R2_Time").disabled == false && R2_Time.trim() == "") { alertMessage += "Please Enter R2 Time \r\n"; }

                if (document.getElementById("R2_Cost").disabled == false && R2_Cost.trim() == "") { alertMessage += "Please Enter R2 Cost \r\n"; }

                if (Math.sign(R2_Cost) == -1) { alertMessage += "Please Enter Positive value of R2 Cost \r\n"; }
                if (document.getElementById("R3_Time").disabled == false && R3_Time.trim() == "") { alertMessage += "Please Enter R3 Time \r\n"; }

                if (document.getElementById("R3_Cost").disabled == false && R3_Cost.trim() == "") { alertMessage += "Please Enter R3 Cost \r\n"; }

                if (Math.sign(R3_Cost) == -1) { alertMessage += "Please Enter Positive value of R3 Cost \r\n"; }
                if (document.getElementById("R4_Time").disabled == false && R4_Time.trim() == "") { alertMessage += "Please Enter R4 Time \r\n"; }

                if (document.getElementById("R4_Cost").disabled == false && R4_Cost.trim() == "") { alertMessage += "Please Enter R4 Cost \r\n"; }
                if (Math.sign(R4_Cost) == -1) { alertMessage += "Please Enter Positive value of R4 Cost \r\n"; }
                if (alertMessage != "") {
                    alert(alertMessage);
                    SetEventTab(CT);
                    return;
                }
                else {
                    SetEventTab(e);
                    setCT(e);
                    setNT("ChangeApproval2");
                    setPT("TimeAndCost");
                }
                break;
            case "ChangeApproval1":
                if ((document.getElementById("ChangeFesibility_releventStakeholders").value).toUpperCase() != "YES") {
                    setChangeFesibility_releventStakeholders("No");
                    setSelectedConstructionSection([]);
                }


                if (PrevPendingWithInitiator.toUpperCase() == "YES") {

                    GodrejConstructionSections = (SelectedConstructionSection.map(i => i.SectionEmail).toString())
                    GodrejSectionDivisions = (SelectedConstructionSection.map(i => i.SectionTeam).toString())

                }
                else {
                    GodrejConstructionSections = document.getElementById("constructionSectionEmailtxt").value;
                    GodrejSectionDivisions = document.getElementById("constructionSectionTeamtxt").value;
                }


                const CountOfStakeHolders = SelectedConstructionSection.length;
                setFeasibilityReportCount(0);

                setChangeFeasibilityAnnexed("Yes");
                setOtherDivision(document.getElementById("OtherDivision").value);
                setOtherDivisionEmail(document.getElementById("OtherDivisionEmail").value);



                setPDASpocName(document.getElementById("pdaspoc").value);

                if ((document.getElementById("IsFinance").value).toUpperCase() != "YES") {
                    setFinanceMemberName("");
                    setIsFinance("No");
                }
                else {
                    setFinanceMemberName(document.getElementById("financeteam").value);
                }

                if ((document.getElementById("ChangeFeasibilityAnnexed").value).toUpperCase() != "YES") {
                    setChangeFeasibilityAnnexed("No");
                }
                var alertMessage = "";
                if (ChangeFesibility_releventStakeholders.trim() == "") { alertMessage += "Please Select Change Fesibility Relevent Stakeholders \r\n"; }

                if (ChangeFesibility_releventStakeholders.trim().toUpperCase() == "YES" && GodrejSectionDivisions == "")
                { alertMessage += "Please Select Godrej Construction Divisions\r\n"; }
                if (ChangeFesibility_releventStakeholders.trim().toUpperCase() == "YES" && GodrejSectionDivisions.trim() != "" && GodrejConstructionSections == "") 
                { alertMessage += "Please Select Godrej Construction Sections\r\n"; }

                // if(OtherDivision.trim()=="" || OtherDivision.trim().toUpperCase()=="SELECT")
                // {alertMessage += "Please Select Other G&B Divisions\r\n";}

                // if((OtherDivision.trim()!="" && OtherDivision.trim().toUpperCase()!="SELECT" ) &&( OtherDivisionEmail.trim().toUpperCase()=="SELECT" || OtherDivisionEmail.trim().toUpperCase()==""))
                // {alertMessage += "Please Select Other Division Email\r\n";}


                if (PDASpocName.trim() == "" || PDASpocName.trim().toUpperCase() == "SELECT") { alertMessage += "Please Select PDASpoc Name\r\n"; }
                if (IsFinance.trim() == "") { alertMessage += "Please Select Send To Finance\r\n"; }
                if (IsFinance.trim().toUpperCase() == "YES" && (FinanceMemberName.trim().toUpperCase() == "SELECT" || FinanceMemberName.trim().toUpperCase() == "")) { alertMessage += "Please Select Finance Member Name\r\n"; }
                if (alertMessage != "") {
                    alert(alertMessage);
                    SetEventTab(CT);
                    return;
                }
                else {
                    SetEventTab(e);
                    setCT(e);
                    setNT("ChangeApproval2")
                    setPT("ChangeApproval1")
                }
                break;
            case "ChangeApproval2":
                SetEventTab(e);
                setCT(e);
                setNT("ChangeApproval2")
                setPT("ChangeApproval1")

                break;
        }
    }
    const SetEventTab = (e) => {
        const arrTAb = ["ChangeInitiation", "TimeAndCost", "ChangeApproval1", "ChangeApproval2"]
        for (let i = 0; i < arrTAb.length; i++) {
            let tabid = "controlled-tab-example-tab-" + arrTAb[i];
            let tabpaneid = "controlled-tab-example-tabpane-" + arrTAb[i];

            if (arrTAb[i] == e) {
                document.getElementById(tabid).removeAttribute('tabIndex');
                document.getElementById(tabid).classList.add('active');
                document.getElementById(tabid).ariaSelected = true;
                document.getElementById(tabpaneid).classList.add('active');
                document.getElementById(tabpaneid).classList.add('show');
            }
            else {
                document.getElementById(tabid).classList.remove('active');
                document.getElementById(tabid).ariaSelected = false;
                document.getElementById(tabid).tabIndex = -1;
                document.getElementById(tabpaneid).classList.remove('active');
                document.getElementById(tabpaneid).classList.remove('show');
            }


        }

    };
    // const chanegEvent = (e) => {

    //     const arrTAb = ["ChangeInitiation", "TimeAndCost", "ChangeApproval1", "ChangeApproval2"]
    //     for (let i = 0; i < arrTAb.length; i++) {
    //         let tabid = "controlled-tab-example-tab-" + arrTAb[i];
    //         let tabpaneid = "controlled-tab-example-tabpane-" + arrTAb[i];

    //         if (arrTAb[i] == e) {

    //             document.getElementById(tabid).removeAttribute('tabIndex');
    //             document.getElementById(tabid).classList.add('active');
    //             document.getElementById(tabid).ariaSelected = true;
    //             document.getElementById(tabpaneid).classList.add('active');
    //             document.getElementById(tabpaneid).classList.add('show');
    //         }
    //         else {
    //             document.getElementById(tabid).classList.remove('active');
    //             document.getElementById(tabid).ariaSelected = false;
    //             document.getElementById(tabid).tabIndex = -1;
    //             document.getElementById(tabpaneid).classList.remove('active');
    //             document.getElementById(tabpaneid).classList.remove('show');
    //         }
    //     }




    // }
    //     const chkValdate = (tmpDate, tmpControl) => {        
    //         const dateObjtmp1 = todaysdate.split(" ")[0].split("/");         
    //         const dateObj1 = new Date(dateObjtmp1[2] + "-" + dateObjtmp1[1] + "-" + dateObjtmp1[0]);
    //         const dateObj2 = new Date(tmpDate);         
    //         if(dateObj1 > dateObj2){
    //            alert( "Selected date is less than todays date" )               
    //            switch(tmpControl) {
    //                case "Original_time":                       
    //                    setOriginal_time("");
    //                  break;
    //                case "R1_Time":
    //                setR1_Time("");
    //                  break;
    //                  case "R2_Time":
    //                setR2_Time("");
    //                  break;
    //                  case "R3_Time":
    //                setR3_Time("");
    //                  break;
    //                  case "R4_Time":
    //                setR4_Time("");
    //                  break;
    //                default:
    //                  return
    //              }
    //         }  

    //    };

    const chkValdate = (tmpDate, tmpControl) => {

        const dateObjtmp1 = todaysdate.split(" ")[0].split("/");
        const dateObj1 = new Date(dateObjtmp1[2] + "-" + dateObjtmp1[1] + "-" + dateObjtmp1[0]);
        const dateObj2 = new Date(tmpDate);
        // if (dateObj1 > dateObj2) {
        // alert("Selected date is less than todays date")
        switch (tmpControl) {
            case "Original_time":
                if (dateObj1 > dateObj2) {
                    alert("Selected date is less than todays date")
                    setOriginal_time("");
                    break;
                }
                if (R1_Time != "") {
                    const dateObj3 = new Date(R1_Time);
                    if (dateObj2 > dateObj3) {
                        alert("Original Time should not greater than R1 Time")
                        setOriginal_time("");
                    }
                }
                break;
            case "R1_Time":
                if (dateObj1 > dateObj2) {
                    alert("Selected date is less than todays date")
                    setR1_Time("");
                    break;
                }
                if (Original_time != "") {
                    const dateObj3 = new Date(Original_time);
                    if (dateObj2 < dateObj3) {
                        alert("R1 Time should not less than Original Time")
                        setR1_Time("");
                        break
                    }
                }
                if (R2_Time != "") {
                    const dateObj3 = new Date(R2_Time);
                    if (dateObj2 > dateObj3) {
                        alert("R1 Time should not greater than R2 Time")
                        setR1_Time("");
                    }
                }

                break;
            case "R2_Time":
                if (dateObj1 > dateObj2) {
                    alert("Selected date is less than todays date")
                    setR2_Time("");
                    break;
                }
                if (R1_Time != "") {
                    const dateObj3 = new Date(R1_Time);
                    if (dateObj2 < dateObj3) {
                        alert("R2 Time should not less than R1 Time")
                        setR2_Time("");
                        break
                    }
                }
                if (R3_Time != "") {
                    const dateObj3 = new Date(R3_Time);
                    if (dateObj2 > dateObj3) {
                        alert("R2 Time should not greater than R3 Time")
                        setR2_Time("");
                    }
                }
                break;
            case "R3_Time":
                if (dateObj1 > dateObj2) {
                    alert("Selected date is less than todays date")
                    setR3_Time("");
                    break;
                }
                if (R2_Time != "") {
                    const dateObj3 = new Date(R2_Time);
                    if (dateObj2 < dateObj3) {
                        alert("R3 Time should not less than R2 Time")
                        setR3_Time("");
                        break
                    }
                }
                if (R4_Time != "") {
                    const dateObj3 = new Date(R4_Time);
                    if (dateObj2 > dateObj3) {
                        alert("R3 Time should not greater than R4 Time")
                        setR3_Time("");
                    }
                }
                break;
            case "R4_Time":
                if (dateObj1 > dateObj2) {
                    alert("Selected date is less than todays date")
                    setR4_Time("");
                    break;
                }
                if (R3_Time != "") {
                    const dateObj3 = new Date(R3_Time);
                    if (dateObj3 > dateObj2) {
                        alert("R4 Time should not less than R3 Time")
                        setR4_Time("");
                    }
                }
                break;
            default:
                return
        }
        // }



    };

    const addsectionArray = (email) => {
        if (email != "" && email.toUpperCase() != "SELECT") {
            const selectedTeam = document.getElementById("constructionSectionTeam").value;
            let dataPresent = "";
            const result = SelectedConstructionSection.filter(obj => {
                return obj.SectionTeam === selectedTeam && obj.SectionEmail === email
            });
            if (result.length == 0) {
                SelectedConstructionSection.push({ SectionTeam: selectedTeam, SectionEmail: email });
            }
        }
        if (SelectedConstructionSection.length == 0) {
            document.getElementById("sectiontable").style.display = "none"
        }
        else {
            document.getElementById("sectiontable").style.display = "block"
        }
    };
    const removesectionArray = (email) => {

        var index = SelectedConstructionSection.map(function (o) { return o.SectionEmail; }).indexOf(email);

        SelectedConstructionSection.splice(index, 1);
        setSelectedConstructionSection([...SelectedConstructionSection])
        if (SelectedConstructionSection.length == 0) {
            settmpGodrejConstructionSections("Select");
            settmpGodrejConstructionSectionsEmail("Select");
        }
    };





    function setDetails() {
        settmpPendingWithInitiator("");
        setOriginalchangenoAndDate(document.getElementById("OriginalchangenoAndDate").value);
        setOriginal_time(document.getElementById("Original_time").value);
        setOriginal_Cost(document.getElementById("Original_Cost").value);

        //BOC by Vikas for date capturing in backend on 08/06/2023
        setStopWorkApprovedByDmcOn(document.getElementById("StopWorkApprovedByDmcOn").value);
        setStopWorkApprovedByPdaOn(document.getElementById("StopWorkApprovedByPdaOn").value);
        //EOC by Vikas for date capturing in backend on 08/06/2023

        setR1_ChangeNoAndDate(document.getElementById("R1_ChangeNoAndDate").value.toString());
        setR1_Time(document.getElementById("R1_Time").value);
        setR1_Cost(document.getElementById("R1_Cost").value);
        setR2_ChangeNoAndDate(document.getElementById("R2_ChangeNoAndDate").value.toString());
        setR2_Time(document.getElementById("R2_Time").value);
        setR2_Cost(document.getElementById("R2_Cost").value);
        setR3_ChangeNoAndDate(document.getElementById("R3_ChangeNoAndDate").value.toString());
        setR3_Time(document.getElementById("R3_Time").value);
        setR3_Cost(document.getElementById("R3_Cost").value);
        setR4_ChangeNoAndDate(document.getElementById("R4_ChangeNoAndDate").value.toString());
        setR4_Time(document.getElementById("R4_Time").value);
        setR4_Cost(document.getElementById("R4_Cost").value);

        setDMCMemberEmail(document.getElementById("DMCMemberEmail").value);
        setDMCMember(document.getElementById("DMCMember").value);
        setChangeFesibility_releventStakeholders(document.getElementById("ChangeFesibility_releventStakeholders").value);
        setNoofDays(document.getElementById("NoofDays").value);


        if (PrevPendingWithInitiator.toUpperCase() == "YES") {

            GodrejConstructionSections = (SelectedConstructionSection.map(i => i.SectionEmail).toString())
            GodrejSectionDivisions = (SelectedConstructionSection.map(i => i.SectionTeam).toString())
            FileLocations = MultipleFileLocations.map(i => i.s3Location).toString();
            FileName = FileNames.map(i => i.Fnames).toString();
            CountOfStakeHolders = GodrejConstructionSections.split(",").length;

        }
        else {
            GodrejConstructionSections = document.getElementById("constructionSectionEmailtxt").value;
            GodrejSectionDivisions = document.getElementById("constructionSectionTeamtxt").value;
            CountOfStakeHolders = GodrejConstructionSections.split(",").length;
        }
        ApprovedByDmcDate = ((document.getElementById("ApprovedByDmcDate").value).toString());
        ApprovedByPDATeamOn = ((document.getElementById("ApprovedByPDATeamOn").value));
        ApprovedByFinanceOn = ((document.getElementById("ApprovedByFinanceOn").value));
        ProjDMCAcceptanceDate = ((document.getElementById("ProjDMCAcceptanceDate").value));
        ApprovedByPdaOn = ((document.getElementById("ApprovedByPdaOn").value).toString());
        GCHeadApprovalDate = ((document.getElementById("GCHeadApprovalDate").value).toString());

    }

    function getCurrentStageDetails() {
        
        Tomail = DMCMemberEmail.toString();
        if (StopWork.toUpperCase().trim() == "YES") {
            if ((DMCMember.toUpperCase().trim() == username.toUpperCase().trim()) && !(document.getElementById('StopWorkApprovedByDMC').disabled) && (StopWork.toUpperCase().trim() === "YES")) {
                currentStage = "StopWorkDMC";

                if (document.getElementById("StopWorkApprovedByDMC").value.toUpperCase().trim() == "NO") {
                    let ans = window.confirm("Do you want to Reject Data?");
                    if (ans) {
                        IsStopWorkRejected = "Yes";
                        StopworkRejectedStage = "StopWorkDMC";
                        RejectedBy = username.toString();
                        RejectionRemarks = "";
                        IsStopWorkPendingWithInitiator = "Yes";
                        Tomail = ChangeInitiationOwnerEmail.toString();
                        return;
                    }
                    else {
                        InvalidSelect = "Yes";
                        return;
                    }
                }
                if (document.getElementById("StopWorkApprovedByDMC").value.toUpperCase().trim() == "YES") {
                    Tomail = PDAStopWorkApprover.toString();
                }
                if (document.getElementById("StopWorkApprovedByDMC").value.toUpperCase().trim() == "SELECT") {
                    alert("Please select Yes or No Option from dropdown");
                    InvalidSelect = "Yes";
                    return;
                }
            }

            if ((PDAStopWorkApprover.toUpperCase().trim() == useremail.toUpperCase().trim()) && !(document.getElementById('StopWorkApprovedByPda').disabled) && StopWork.toUpperCase().trim() === "YES") {
                currentStage = "StopWorkPDAHead";


                if (document.getElementById("StopWorkApprovedByPda").value.toUpperCase().trim() == "NO") {
                    let ans = window.confirm("Do you want to Reject Data?");
                    if (ans) {

                        IsStopWorkRejected = "Yes";
                        StopworkRejectedStage = "StopWorkPDAHead";
                        RejectedBy = username.toString();
                        RejectionRemarks = document.getElementById("PdastopworkapprovalRemarks").value.toString();
                        IsStopWorkPendingWithInitiator = "Yes";
                        Tomail = ChangeInitiationOwnerEmail.toString();
                        CCMail = DMCMemberEmail;

                    }
                    else {
                        InvalidSelect = "Yes";
                        return;
                    }
                }
                if (document.getElementById("StopWorkApprovedByPda").value.toUpperCase().trim() == "YES") {
                    Tomail = BHHeadEmail.toString();
                }
                if (document.getElementById("StopWorkApprovedByPda").value.toUpperCase().trim() == "SELECT") {
                    alert("Please select Yes or No Option from dropdown");
                    InvalidSelect = "Yes";
                    return;

                }
            }

            if ((GCStopWorkApprover.toUpperCase().trim() == useremail.toUpperCase().trim()) && !(document.getElementById('StopWorkApprovedByGC').disabled) && StopWork.toUpperCase().trim().toString() === "YES") {
                currentStage = "StopWorkBHHead";

                if (document.getElementById("StopWorkApprovedByGC").value.toUpperCase().trim() == "NO") {
                    let ans = window.confirm("Do you want to Reject Data?");
                    if (ans) {
                        IsStopWorkRejected = "Yes";
                        StopworkRejectedStage = "StopWorkBHHead";
                        RejectedBy = username.toString();
                        RejectionRemarks = document.getElementById("RemarkByBH").value.toString();
                        IsStopWorkPendingWithInitiator = "Yes";
                        Tomail = ChangeInitiationOwnerEmail.toString();
                        CCMail = DMCMemberEmail + ";" + PDAStopWorkApprover;
                    }
                    else {
                        InvalidSelect = "Yes";
                        return;
                    }
                }
                if (document.getElementById("StopWorkApprovedByGC").value.toUpperCase().trim() == "YES") {
                    Tomail = ChangeInitiationOwnerEmail.toString();
                }

                if (document.getElementById("StopWorkApprovedByGC").value.toUpperCase().trim() == "SELECT") {
                    alert("Please select Yes or No Option from dropdown");
                    InvalidSelect = "Yes";
                    return;
                }

            };

        }
        else {
            if ((DMCMember.toUpperCase().trim() == username.toUpperCase().trim()) && !(document.getElementById('ApprovedByDmc').disabled) && StopWork.toUpperCase().trim() !== "YES") {

                currentStage = "DMC";

                if (document.getElementById("ApprovedByDmc").value.toUpperCase().trim() == "NO") {
                    let ans = window.confirm("Do you want to Reject Data?");
                    if (ans) {
                        IsRejected = "Yes";
                        RejectedStage = "DMC";
                        RejectedBy = username.toString();
                        RejectionRemarks = document.getElementById("RemarksforRejection").value.toString();
                        PendingWithInitiator = "Yes";
                        Tomail = ChangeInitiationOwnerEmail.toString();
                        GodrejSectionDivisions = "";
                        GodrejConstructionSections = "";
                        setChangeFesibility_releventStakeholders("No");
                        CountOfStakeHolders = 0;
                        FileLocations = "";
                        FileName = "";
                        return;
                    }
                    else {
                        InvalidSelect = "Yes";
                        return;
                    }
                }
                if (document.getElementById("ApprovedByDmc").value.toUpperCase().trim() == "YES") {
                    if (document.getElementById("ChangeFesibility_releventStakeholders").value.toUpperCase().trim() == "YES") {
                        Tomail = GodrejConstructionSections.toString();
                        Tomail = Tomail.replace(/,/g, ";");
                    } else {
                        Tomail = PDASpocEmail.toString();
                    }
                }
                if (document.getElementById("ApprovedByDmc").value.toUpperCase().trim() == "SELECT") {
                    alert("Please select Yes or No Option from dropdown");
                    InvalidSelect = "Yes";
                    return;
                }
            }

            if ((PDASpocName.toUpperCase().trim() == username.toUpperCase().trim()) && !(document.getElementById('ApprovedByPDATeam').disabled) && StopWork.toUpperCase().trim() !== "YES") {
                currentStage = "PDASPOC";

                if (document.getElementById("ApprovedByPDATeam").value.toUpperCase().trim() == "NO") {
                    let ans = window.confirm("Do you want to Reject Data?");
                    if (ans) {

                        IsRejected = "Yes";
                        RejectedStage = "PDASpoc";
                        RejectedBy = username.toString();
                        RejectionRemarks = document.getElementById("RemarkByPDA").value.toString();
                        PendingWithInitiator = "Yes";
                        Tomail = ChangeInitiationOwnerEmail.toString();
                        if (document.getElementById("ChangeFesibility_releventStakeholders").value.toUpperCase().trim() == "YES") {

                            CCMail = DMCMemberEmail + ";" + GodrejConstructionSections.toString().replace(/,/g, ";");
                        } else {
                            CCMail = DMCMemberEmail;
                        }

                        GodrejSectionDivisions = "";
                        GodrejConstructionSections = "";
                        setChangeFesibility_releventStakeholders("No");
                        CountOfStakeHolders = 0;
                        FileLocations = "";
                        FileName = "";
                    }
                    else {
                        InvalidSelect = "Yes";
                        return;
                    }
                }
                if (document.getElementById("ApprovedByPDATeam").value.toUpperCase().trim() == "YES") {
                    Tomail = DMCMemberOwner.toString();
                }
                if (document.getElementById("ApprovedByPDATeam").value.toUpperCase().trim() == "SELECT") {
                    alert("Please select Yes or No Option from dropdown");
                    InvalidSelect = "Yes";
                    return;

                }
            }

            if ((FinanceApprover.toUpperCase().trim() == username.toUpperCase().trim()) && !(document.getElementById('ApprovedByFinance').disabled) && StopWork.toUpperCase().trim() !== "YES") {
                currentStage = "Finance";
                if (document.getElementById("ApprovedByFinance").value.toUpperCase().trim() == "NO") {
                    let ans = window.confirm("Do you want to Reject Data?");
                    if (ans) {
                        IsRejected = "Yes";
                        RejectedStage = "Finance";
                        RejectedBy = username.toString();
                        RejectionRemarks = document.getElementById("RemarkByFinance").value.toString();
                        PendingWithInitiator = "Yes";
                        Tomail = ChangeInitiationOwnerEmail.toString();
                        CCMail = DMCMemberEmail + "," + GodrejConstructionSections.toString().replace(/,/g, ";");
                        GodrejSectionDivisions = "";
                        GodrejConstructionSections = "";
                        setChangeFesibility_releventStakeholders("No");
                        CountOfStakeHolders = 0;
                        FileLocations = "";
                        FileName = "";
                    }
                    else {
                        InvalidSelect = "Yes";
                        return;
                    }
                }

                if (document.getElementById("ApprovedByFinance").value.toUpperCase().trim() == "YES") {
                    Tomail = DMCMemberOwner.toString();
                }
                if (document.getElementById("ApprovedByFinance").value.toUpperCase().trim() == "SELECT") {
                    alert("Please select Yes or No Option from dropdown");
                    InvalidSelect = "Yes";
                    return;

                }
            }

            if ((DMCMemberOwner.toUpperCase().trim() == useremail.toUpperCase().trim()) && !(document.getElementById('ChangeAcceptedbyDMC').disabled) && StopWork.toUpperCase().trim() !== "YES") {

                currentStage = "ProjDMC";

                if (document.getElementById("ChangeAcceptedbyDMC").value.toUpperCase().trim() == "NO") {
                    let ans = window.confirm("Do you want to Reject Data?");
                    if (ans) {
                        IsRejected = "Yes";
                        RejectedStage = "ProjDMC";
                        RejectedBy = username.toString();
                        RejectionRemarks = document.getElementById("RemarkByProjDmc").value.toString();
                        PendingWithInitiator = "Yes";
                        Tomail = ChangeInitiationOwnerEmail.toString();
                        CCMail = DMCMemberEmail + ";" + GodrejConstructionSections.toString().replace(/,/g, ";") + ";" + PDASpocEmail;
                        GodrejSectionDivisions = "";
                        GodrejConstructionSections = "";
                        setChangeFesibility_releventStakeholders("No");
                        CountOfStakeHolders = 0;
                        FileLocations = "";
                        FileName = "";
                    }
                    else {
                        InvalidSelect = "Yes";
                        return;
                    }
                }
                if (document.getElementById("ChangeAcceptedbyDMC").value.toUpperCase().trim() == "YES") {
                    Tomail = PDAHeadEmail.toString();
                }
                if (document.getElementById("ChangeAcceptedbyDMC").value.toUpperCase().trim() == "SELECT") {
                    alert("Please select Yes or No Option from dropdown");
                    InvalidSelect = "Yes";
                    return;
                }
            }

            if ((PDAApprover.toUpperCase().trim() == username.toUpperCase().trim()) && !(document.getElementById('ChangeValidatedByPDA').disabled) && StopWork.toUpperCase().trim() !== "YES") {
                currentStage = "PDAHead";
                if (document.getElementById("ChangeValidatedByPDA").value.toUpperCase().trim() == "NO") {
                    let ans = window.confirm("Do you want to Reject Data?");
                    if (ans) {
                        IsRejected = "Yes";
                        RejectedStage = "PDAHead";
                        RejectedBy = username.toString();
                        RejectionRemarks = document.getElementById("RemarkByPdaHead").value.toString();
                        PendingWithInitiator = "Yes";
                        Tomail = ChangeInitiationOwnerEmail.toString();
                        CCMail = DMCMemberEmail + ";" + GodrejConstructionSections.toString().replace(/,/g, ";") + ";" + PDASpocEmail + ";" + DMCMemberOwner;
                        GodrejSectionDivisions = "";
                        GodrejConstructionSections = "";
                        setChangeFesibility_releventStakeholders("No");
                        CountOfStakeHolders = 0;
                        FileLocations = "";
                        FileName = "";
                    }
                    else {
                        InvalidSelect = "Yes";
                        return;
                    }
                }
                if (document.getElementById("ChangeValidatedByPDA").value.toUpperCase().trim() == "YES") {
                    Tomail = BHHeadEmail.toString();
                }
                if (document.getElementById("ChangeValidatedByPDA").value.toUpperCase().trim() == "SELECT") {
                    alert("Please select Yes or No Option from dropdown");
                    InvalidSelect = "Yes";
                    return;
                }
            }


            if ((GCApprover.toUpperCase().trim() == username.toUpperCase().trim()) && !(document.getElementById('ChangeApprovedbyGC').disabled) && StopWork.toUpperCase().trim() !== "YES") {
                currentStage = "BHHead";
                if (document.getElementById("ChangeApprovedbyGC").value.toUpperCase().trim() == "NO") {
                    let ans = window.confirm("Do you want to Reject Data?");
                    if (ans) {
                        IsRejected = "Yes";
                        RejectedStage = "BHHead";
                        RejectedBy = username.toString();
                        RejectionRemarks = document.getElementById("RemarkByBH").value.toString();
                        PendingWithInitiator = "Yes";
                        Tomail = ChangeInitiationOwnerEmail.toString();
                        CCMail = DMCMemberEmail + ";" + GodrejConstructionSections.toString().replace(/,/g, ";") + ";" + PDASpocEmail + ";" + DMCMemberOwner + ";" + PDAHeadEmail;
                        GodrejSectionDivisions = "";
                        GodrejConstructionSections = "";
                        setChangeFesibility_releventStakeholders("No");
                        CountOfStakeHolders = 0;
                        FileLocations = "";
                        FileName = "";

                    }
                    else {
                        InvalidSelect = "Yes";
                        return;
                    }
                }
                if (document.getElementById("ChangeApprovedbyGC").value.toUpperCase().trim() == "YES") {
                    Tomail = ChangeInitiationOwnerEmail.toString();
                }
                if (document.getElementById("ChangeApprovedbyGC").value.toUpperCase().trim() == "SELECT") {
                    alert("Please select Yes or No Option from dropdown");
                    InvalidSelect = "Yes";
                    return;

                }

            };

        }


    };

    const btnCancelClick = async (e) => {

        var NavPath = sessionStorage.getItem("NavPath");
        navigate(NavPath);

    }
    const validateNumber = async (tmpcost, tmpid) => {
        var t = "";
        t = ((tmpcost.indexOf(".") >= 0) ? (tmpcost.substr(0, tmpcost.indexOf(".")) + tmpcost.substr(tmpcost.indexOf("."), 3)) : tmpcost);
        switch (tmpid) {
            case "Original_Cost":
                setOriginal_Cost(t);
                break;
            case "R1_Cost":
                setR1_Cost(t);
                break;
            case "R2_Cost":
                setR2_Cost(t);
                break;
            case "R3_Cost":
                setR3_Cost(t);
                break;
            case "R4_Cost":
                setR4_Cost(t);
            case "RevisedBudget":
                setRevisedBudget(t);
                break;
            default:
                return
        }
    }

    const validateNumberofDays = async (tmpNoofDays) => {

        setNoofDays(tmpNoofDays.split(".")[0])

        if (Math.sign(tmpNoofDays) == -1) {
            alert("Please Enter Positive value of  No of Days");
            setNoofDays("");
        }

    }

    // function updateData() {
    const updateData = async (e) => {
        
        console.log("ApprovedByDmc ", ApprovedByDmc, " ApprovedByPDATeam ", ApprovedByPDATeam, " ApprovedByFinance ", ApprovedByFinance, " ChangeAcceptedbyDMC ", ChangeAcceptedbyDMC,
            " ChangeValidatedByPDA ", ChangeValidatedByPDA, " ChangeApprovedbyGC ", ChangeApprovedbyGC)
        setDetails();
        getCurrentStageDetails();
        if (InvalidSelect.toString().toUpperCase().trim() != "YES") {

            //Added condition by Vikas for checking stopwork is approved or not 
            if (IsRejected.toUpperCase() != "YES" && IsStopWorkRejected.toUpperCase().trim() != "YES") {
                let ans = window.confirm("Do you want to Approve Request?");
                if (ans) {
                    if (ApprovedByDmc.toUpperCase() != "YES") { setApprovedByDmc(""); }
                    if (ApprovedByPDATeam.toUpperCase() != "YES") { setApprovedByPDATeam(""); }
                    if (ApprovedByFinance.toUpperCase() != "YES") { setApprovedByFinance(""); }
                    if (ChangeAcceptedbyDMC.toUpperCase() != "YES") { setChangeAcceptedbyDMC(""); }
                    if (ChangeValidatedByPDA.toUpperCase() != "YES") { setChangeValidatedByPDA(""); }
                    if (ChangeApprovedbyGC.toUpperCase() != "YES") { setChangeApprovedbyGC(""); }
                    if (StopWorkApprovedByDMC.toUpperCase() != "YES") { setStopWorkApprovedByDMC(""); }
                    if (StopWorkApprovedByPda.toUpperCase() != "YES") { setStopWorkApprovedByPda(""); }
                    if (StopWorkApprovedByGC.toUpperCase() != "YES") { setStopWorkApprovedByGC(""); }


                    var alertMessage = "";

                    if (ProjectName.trim() == "") { alertMessage += "Please select Project \r\n"; }
                    if (WorkPackage.trim() == "") { alertMessage += "Please select WorkPackage \r\n"; }
                    if (Existing.trim() == "") { alertMessage += "Please Enter Existing field\r\n"; }

                    if (Proposed.trim() == "") { alertMessage += "Please Enter Proposed field \r\n"; }
                    if (JustificationOfChange.trim() == "") { alertMessage += "Please Enter JustificationOfChange field \r\n"; }
                    if (AssumptionsConstraints.trim() == "") { alertMessage += "Please Enter AssumptionsConstraints field \r\n"; }

                    if (document.getElementById("Original_time").disabled == false && Original_time.trim() == "") { alertMessage += "Please Enter Original Time \r\n"; }

                    if (document.getElementById("Original_Cost").disabled == false && Original_Cost.trim() == "") { alertMessage += "Please Enter Original Cost \r\n"; }

                    if (document.getElementById("R1_Time").disabled == false && R1_Time.trim() == "") { alertMessage += "Please Enter R1 Time \r\n"; }

                    if (document.getElementById("R1_Cost").disabled == false && R1_Cost.trim() == "") { alertMessage += "Please Enter R1 Cost \r\n"; }
                    if (document.getElementById("R2_Time").disabled == false && R2_Time.trim() == "") { alertMessage += "Please Enter R2 Time \r\n"; }

                    if (document.getElementById("R2_Cost").disabled == false && R2_Cost.trim() == "") { alertMessage += "Please Enter R2 Cost \r\n"; }
                    if (document.getElementById("R3_Time").disabled == false && R3_Time.trim() == "") { alertMessage += "Please Enter R3 Time \r\n"; }

                    if (document.getElementById("R3_Cost").disabled == false && R3_Cost.trim() == "") { alertMessage += "Please Enter R3 Cost \r\n"; }
                    if (document.getElementById("R4_Time").disabled == false && R4_Time.trim() == "") { alertMessage += "Please Enter R4 Time \r\n"; }

                    if (document.getElementById("R4_Cost").disabled == false && R4_Cost.trim() == "") { alertMessage += "Please Enter R4 Cost \r\n"; }

                    if (ChangeFesibility_releventStakeholders.trim() == "") { alertMessage += "Please select ChangeFesibility_releventStakeholders \r\n"; }
                    if (ChangeFesibility_releventStakeholders.trim().toUpperCase() == "YES" && GodrejSectionDivisions == "") { alertMessage += "Please select Godrej Construction Divisions\r\n"; }
                    if (ChangeFesibility_releventStakeholders.trim().toUpperCase() == "YES" && GodrejSectionDivisions.trim != "" && GodrejConstructionSections == "") { alertMessage += "Please select Godrej Construction Sections\r\n"; }
                    if (PDASpocName.trim() == "") { alertMessage += "Please select PDASpoc Name\r\n"; }
                    if (IsFinance.trim() == "") { alertMessage += "Please select Send To Finance\r\n"; }
                    if (IsFinance.trim().toUpperCase() == "YES" && (FinanceMemberName.trim().toUpperCase() == "SELECT" || FinanceMemberName.trim().toUpperCase() == "")) { alertMessage += "Please select Finance Member Name\r\n"; }
                    if (alertMessage != "") {
                        alert(alertMessage);
                        return;
                    }
                }
                else {
                    return;
                }
            }

            let EmailSubject = "";
            EmailSubject = "Change note for Project " + ProjectName + " " + ChangeRequestNumber + " ";
            let EmailBody = "";
            if (IsRejected == "Yes" || IsStopWorkRejected == "Yes") {

                EmailBody = "Dear Sir / Madam,<br /><br />"
                EmailBody += "A Change note for " + ProjectName + ". ( " + ChangeRequestNumber + ") has been Rejected by " + username + " and same is pending for your inputs and necessary action.<br />"
                EmailBody += "Kindly log into the App or Following link to approve / reject / inputs. <br />"
                EmailBody += '<a href="https://www.changemanagementgc.godrejenterprises.com/"> Click here </a><br /><br />'
                EmailBody += "Regards,<br />"
                EmailBody += "Team Construction.<br /><br />"
                EmailBody += "This is an automated mail, do not reply. ";
            }
            else {
                if (ChangeApprovedbyGC.toUpperCase() == "YES") {
                    EmailBody = "Dear Sir / Madam,<br /><br />"
                    EmailBody += "A Change note for " + ProjectName + " Change management App ( " + ChangeRequestNumber + ") has been Approved Successfully by Business Head  " + GCApprover + ". <br /> <br /> "
                    EmailBody += "Regards,<br /> "
                    EmailBody += "Team Construction.<br /> <br /> "
                    EmailBody += "This is an automated mail, do not reply. ";
                }
                else {
                    EmailBody = "Dear Sir / Madam,<br /><br />"
                    EmailBody += "A Change note for " + ProjectName + ". ( " + ChangeRequestNumber + ") has been initiated by " + ChangeInitiationOwner + " and same is pending for your inputs and necessary action.<br />"
                    EmailBody += "Kindly log into the App or Following link to approve / reject / inputs. <br />"
                    EmailBody += '<a href="https://www.changemanagementgc.godrejenterprises.com/"> Click here </a><br /><br />'
                    EmailBody += "Regards,<br />"
                    EmailBody += "Team Construction.<br /><br />"
                    EmailBody += "This is an automated mail, do not reply. ";

                }

            }


            //Added 'IsStopWorkRejected' in ChangeInitiation object by Vikas on 27/06/2023
            const ChangeInitiation = {
                ProjectNumber, ChangeRequestNumber, OriginalchangenoAndDate, Original_time, Original_Cost, R1_ChangeNoAndDate, R1_Time, R1_Cost, R2_ChangeNoAndDate, R2_Time, R2_Cost, R3_ChangeNoAndDate, R3_Time, R3_Cost, R4_ChangeNoAndDate, R4_Time, R4_Cost,
                WorkPackage, Existing, Proposed, JustificationOfChange, AssumptionsConstraints, KeyRisks, FileLocations, FileName
                , ChangeFesibility_releventStakeholders, GodrejConstructionSections, GodrejSectionDivisions, OtherDivision, OtherDivisionEmail, PDASpocName, IsFinance, FinanceMemberName,
                ChangeFeasibilityAnnexed, StopWork, NoofDays, StopWorkApprovedByDMC, StopWorkApprovedByDmcOn, StopWorkApprovedByPda, PdastopworkapprovalRemarks, StopWorkApprovedByPdaOn,
                StopWorkApprovedByGC, GCstopworkapprovalRemarks, GCStopWorkApproveDate, IsRejected, IsStopWorkRejected , RejectedStage, RejectedBy, RejectionRemarks,
                IsStopWorkPendingWithInitiator, PendingWithInitiator, ApprovedByDmc, Modified, ModifiedBy, RemarksforRejection, ApprovedByDmcDate,
                ApprovedByPDATeam, RemarkByPdaHead, RemarkByPDA, ApprovedByPDATeamOn, ApprovedByFinance, RemarkByFinance, ApprovedByFinanceOn,
                ChangeAcceptedbyDMC, RemarkByProjDmc, ProjDMCAcceptanceDate, ChangeValidatedByPDA, CountOfStakeHolders, ApprovedByPdaOn,
                ChangeApprovedbyGC, RemarkByBH, GCHeadApprovalDate, Tomail, CCMail, EmailSubject, EmailBody, todaysdate, OriginalCommitment_Time, OriginalCommitmentCost,
                LastApproved_Time, LastApprovedCost, NewProposed_time, NewProposed_Cost, Impact_EndDate, Impact_Budget, RevisedEndDate, RevisedBudget
            };
            //          fetch(`${API_URL}/ProjectNumber/${ProjectNumber}/ChangeRequestNumber/${ChangeRequestNumber}`, {
            //             method: 'PUT',
            //             headers: {
            //                 'Accept': 'application/json',
            //                 'Content-Type': 'application/json'
            //             },
            //             body: JSON.stringify(ChangeInitiation)
            //         }).then((resp) => { 
            //             alert("Change Request Number " + ChangeRequestNumber + " Updated Successfully");
            //             var NavPath= sessionStorage.getItem("NavPath");       

            //             navigate(NavPath, {replace: true}); 




            //         })
            //     }
            // }
            console.log("ChangeInitiation " , ChangeInitiation);
            console.log(" 11 ApprovedByDmc ", ApprovedByDmc, " ApprovedByPDATeam ", ApprovedByPDATeam, " ApprovedByFinance ", ApprovedByFinance, " ChangeAcceptedbyDMC ", ChangeAcceptedbyDMC,
                " ChangeValidatedByPDA ", ChangeValidatedByPDA, " ChangeApprovedbyGC ", ChangeApprovedbyGC)

            UpdateChangeInitiation(ChangeInitiation)
                .then((resp) => {
                    console.log("resp");
                    console.log(resp);
                    if (resp.data.status) {
                        console.log(resp.data.Message);
                        navigate('/Raised', { replace: true });
                        alert("Change Initiation Request Number " + ChangeRequestNumber + " Updated Successfully");
                        console.log(" 22 ApprovedByDmc ", ApprovedByDmc, " ApprovedByPDATeam ", ApprovedByPDATeam, " ApprovedByFinance ", ApprovedByFinance, " ChangeAcceptedbyDMC ", ChangeAcceptedbyDMC,
                            " ChangeValidatedByPDA ", ChangeValidatedByPDA, " ChangeApprovedbyGC ", ChangeApprovedbyGC)

                    }
                    else {
                        console.log(resp.data.Message);
                        navigate('/Raised', { replace: true });
                        alert(resp.data.Message);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
                console.log("response");

        }
    }
    const onSwitchAction = () => {
        if (document.getElementById("stopwork").checked == true) {
            setStopWork("Yes");
        }
        else {
            setStopWork("No");
            setNoofDays("");
        }
    };

    return (
        <Container className="LogbookForm">
            <div class="float-end m-1">
                <Button class="btn-primary"
                    style={{ float: "right" }}
                    size="sm"
                    onClick={onBackClick}
                >
                    Back
                </Button>

            </div>

            <br />
            <h5> <span class="text-primary">Change Initiation </span></h5>
            {/* <h2 >Create New LogBook Request</h2> */}
            <hr />
            {ChnageInitiationDetails.map((Cidata, index) =>
                <Form className="form" >  {/*  //onSubmit={handleSubmit} */}
                    <Tabs id="controlled-tab-example" className="mb-3" onSelect={(e) => { chanegEvent(e) }}>
                        <Tab eventKey="ChangeInitiation" disabled id="ChangeInitiation" title="Change Initiation">
                            <Row>
                                <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "right" }} size="sm"
                                    onClick={(e) => { chanegEvent("TimeAndCost") }} > Next </Button> </div>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label class="required-field"> Work Type (SI / MI-A / MI-B / DI-A / DI-B / OI-A / OI-B / Project):</Form.Label>
                                        <Form.Control type="text" value={Cidata.WorkType} disabled name="WorkType" />
                                    </Form.Group></Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label class="required-field"> Teams</Form.Label>
                                        <Form.Control aria-label="Teams" name="TeamName" id="TeamName" disabled type="text" value={Cidata.TeamName} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>

                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label class="required-field"> Initiative / Project Name</Form.Label>
                                        <Form.Control aria-label="ProjectName" id="ProjectName" disabled name="ProjectName" value={Cidata.ProjectName} />
                                    </Form.Group></Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Initiative / Project Number</Form.Label>
                                        <Form.Control aria-label="ProjectNumber" id="ProjectNumber" disabled name="ProjectNumber" value={Cidata.ProjectNumber} />
                                    </Form.Group></Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Initiative / Project Owner</Form.Label>
                                        <Form.Control aria-label="ProjectOwner" id="ProjectOwner" disabled name="ProjectOwner" value={Cidata.ProjectOwner} />
                                    </Form.Group></Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label class="required-field"> Project DMC Member</Form.Label>
                                        <Form.Control aria-label="DMCMemberOwner" disabled id="DMCMemberOwner" name="DMCMemberOwner" value={Cidata.DMCMemberOwner} />

                                    </Form.Group></Col>
                            </Row>
                            <h5> <span class="text-primary">Part A - Change Initiation Section </span></h5>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" value={Cidata.ChangeInitiationOwner} disabled id="ChangeInitiationOwner" name="Name" />

                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>DMC Member (Seeking Change)</Form.Label>
                                        <Form.Control type="text" value={Cidata.DMCMember} name="Dmcmember" disabled />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Request Date</Form.Label>
                                        <Form.Control type="text" id="ChangeRequestDate" disabled value={Cidata.ChangeRequestDate} name="ChangeRequestDate" />

                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Change Request Number</Form.Label>
                                        <Form.Control type="text" value={Cidata.ChangeRequestNumber} disabled name="ChangeRequestNumber" />

                                    </Form.Group>
                                </Col>
                            </Row>
                            <h5> <span class="text-primary">Description & Details of Changes proposed  </span></h5>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label class="required-field"> Work Package</Form.Label>
                                        <Form.Select aria-label="WorkPackage" name="WorkPackage" disabled={(Cidata.PendingWithInitiator === "Yes" && Cidata.ChangeInitiationOwner === username) ? false : true} value={WorkPackage} onChange={(e) => setWorkPackage(e.target.value)} >
                                            {WorkPackageOptions.map((item, index) => {
                                                return <option key={index} value={item.WorkPackageDetail}>{item.WorkPackageDetail}</option>
                                            })}
                                        </Form.Select>
                                    </Form.Group></Col>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label></Form.Label>

                                        <Form.Control type="text" aria-label="WorkPackage" name="WorkPackage" disabled value={WorkPackage} />

                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label class="required-field"> Existing</Form.Label>
                                        <Form.Control as="textarea" rows={5} value={Existing} disabled={(Cidata.PendingWithInitiator === "Yes" && Cidata.ChangeInitiationOwner === username) ? false : true} name="Existing" onChange={(e) => setExisting(e.target.value)} />
                                    </Form.Group>
                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label class="required-field"> Proposed</Form.Label>
                                        <Form.Control as="textarea" rows={5} value={Proposed} name="Proposed" disabled={(Cidata.PendingWithInitiator === "Yes" && Cidata.ChangeInitiationOwner === username) ? false : true} onChange={(e) => setProposed(e.target.value)} />
                                    </Form.Group>
                                </Col>

                            </Row>
                            <Row>
                                <Form.Label>Attachment (Size per file upto 5 MB max (max 5 files allowed), allowed file types are .doc, .pdf, .xls, .xlsx, .mst, .ppt, .dwg) </Form.Label>
                            </Row>
                            <Row id="Dropzone" disabled={(Cidata.PendingWithInitiator === "Yes" && Cidata.ChangeInitiationOwner === username) ? false : true}>
                                <Row>

                                    <div>
                                        <input
                                            id="attachmentbtn"
                                            type="file"
                                            title="Select or Drag Files here"
                                            onChange={onFileDrop}
                                            disabled={fileList.length >= 5 ? true : false}

                                        />
                                    </div>
                                </Row><br></br> <Row>
                                    <div>
                                        {fileList.length > 0 ? (
                                            <div className="files-list">
                                                {fileList.map((item, index) => (
                                                    <div className="files-list-map" key={index}>
                                                        <button className="files-report-icon" />
                                                        <span className="files-note">{item.name}</span>
                                                        <button
                                                            className="files-trash-icon"
                                                            onClick={() => fileRemove(item)}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>
                                </Row>
                                <br></br>


                            </Row>
                            <Row id="attachmentrow" >
                            </Row>
                            <br></br>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label class="required-field"> Key Reasons / Justification / Benefits for Change</Form.Label>
                                        <Form.Control as="textarea" rows={5} value={JustificationOfChange} disabled={(Cidata.PendingWithInitiator === "Yes" && Cidata.ChangeInitiationOwner === username) ? false : true} name="JustificationOfChange" onChange={(e) => setJustificationOfChange(e.target.value)} />

                                    </Form.Group>
                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label class="required-field"> Assumptions / Constraints</Form.Label>
                                        <Form.Control as="textarea" rows={5} value={AssumptionsConstraints} disabled={(Cidata.PendingWithInitiator === "Yes" && Cidata.ChangeInitiationOwner === username) ? false : true} name="AssumptionsConstraints" onChange={(e) => setAssumptionsConstraints(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label class="required-field"> Key Risks</Form.Label>
                                        <Form.Control as="textarea" rows={5} value={KeyRisks} disabled={(Cidata.PendingWithInitiator === "Yes" && Cidata.ChangeInitiationOwner === username) ? false : true} name="keyrisks" onChange={(e) => setKeyRisks(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row><br></br></Row>
                            <Row>
                                <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "right" }} size="sm"
                                    onClick={(e) => { chanegEvent("TimeAndCost") }} > Next </Button> </div>
                            </Row>
                        </Tab>
                        <Tab eventKey="TimeAndCost" disabled id="TimeAndCost" title="Time And Cost">
                            <Row>
                                <div>
                                    <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "right" }} size="sm"
                                        onClick={(e) => { chanegEvent("ChangeApproval1") }} > Next </Button> </div>
                                    <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "left" }} size="sm"
                                        onClick={(e) => { chanegEvent("ChangeInitiation") }} > Prev </Button> </div>

                                </div>
                            </Row>
                            <h5> <span class="text-primary">Time and Cost target</span></h5>
                            <Row>
                                <table class="table table-small">
                                    <thead className="table-head">
                                        <tr>
                                            <th></th>
                                            <th>Original Commitment</th>
                                            <th>R1</th>
                                            <th>R2</th>
                                            <th>R3</th>
                                            <th>R4</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-body">
                                        <tr>
                                            <td>   <Form.Label>Change No. & Dt</Form.Label></td>
                                            <td> <Form.Control type="text" id="OriginalchangenoAndDate" value={OriginalchangenoAndDate} name="OriginalchangenoAndDate" />
                                            </td>
                                            <td> <Form.Control type="text" id="R1_ChangeNoAndDate" value={R1_ChangeNoAndDate} disabled name="R1_ChangeNoAndDate" />
                                            </td>
                                            <td> <Form.Control type="text" id="R2_ChangeNoAndDate" value={R2_ChangeNoAndDate} disabled name="R2_ChangeNoAndDate" />
                                            </td>
                                            <td> <Form.Control type="text" id="R3_ChangeNoAndDate" value={R3_ChangeNoAndDate} disabled name="R3_ChangeNo" />
                                            </td>
                                            <td> <Form.Control type="text" id="R4_ChangeNoAndDate" value={R4_ChangeNoAndDate} disabled name="R4_ChangeNo" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>   <Form.Label class="required-field"> Time</Form.Label></td>

                                            <td>  <Form.Control type="date" id="Original_time" value={Original_time} disabled={((Cidata.ChangeInitiationOwner.toString() === username.toString() || Cidata.DMCMember.toString() === username.toString()) && (RevisionNo >= 1) && (NavigationPath != "/approvedrequest")) ? false : true} name="Original_time" onBlur={(e) => chkValdate(e.target.value, "Original_time")} onChange={(e) => { setOriginal_time(e.target.value) }} />
                                            </td>
                                            <td>
                                                <Form.Control type="date" id="R1_Time" value={R1_Time} name="R1_Time" disabled={((Cidata.ChangeInitiationOwner.toString() === username.toString() || Cidata.DMCMember.toString() === username.toString()) && (RevisionNo >= 1) && (NavigationPath != "/approvedrequest")) ? false : true} onBlur={(e) => chkValdate(e.target.value, "R1_Time")} onChange={(e) => { setR1_Time(e.target.value) }} />

                                            </td>
                                            <td>
                                                <Form.Control type="date" id="R2_Time" value={R2_Time} name="R2_Time" disabled={((Cidata.ChangeInitiationOwner.toString() === username.toString() || Cidata.DMCMember.toString() === username.toString()) && (RevisionNo >= 2) && (NavigationPath != "/approvedrequest")) ? false : true} onBlur={(e) => chkValdate(e.target.value, "R2_Time")} onChange={(e) => { setR2_Time(e.target.value) }} />

                                            </td>
                                            <td>
                                                <Form.Control type="date" id="R3_Time" value={R3_Time} name="R3_Time" disabled={((Cidata.ChangeInitiationOwner.toString() === username.toString() || Cidata.DMCMember.toString() === username.toString()) && (RevisionNo >= 3) && (NavigationPath != "/approvedrequest")) ? false : true} onBlur={(e) => chkValdate(e.target.value, "R3_Time")} onChange={(e) => { setR3_Time(e.target.value) }} />

                                            </td>
                                            <td>
                                                <Form.Control type="date" id="R4_Time" value={R4_Time} name="R4_Time" disabled={((Cidata.ChangeInitiationOwner.toString() === username.toString() || Cidata.DMCMember.toString() === username.toString()) && (RevisionNo >= 4) && (NavigationPath != "/approvedrequest")) ? false : true} onBlur={(e) => chkValdate(e.target.value, "R4_Time")} onChange={(e) => { setR4_Time(e.target.value) }} />

                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <Form.Label class="required-field"> Cost</Form.Label></td>
                                            <td> <Form.Control type="number" id="Original_Cost" value={Original_Cost} disabled={((Cidata.ChangeInitiationOwner.toString() === username.toString() || Cidata.DMCMember.toString() === username.toString()) && (RevisionNo >= 1) && (NavigationPath != "/approvedrequest")) ? false : true} name="Original_Cost" onChange={(e) => { setOriginal_Cost(e.target.value); validateNumber(e.target.value, "Original_Cost") }} />
                                            </td>
                                            <td> <Form.Control type="number" id="R1_Cost" value={R1_Cost} name="R1_Cost" disabled={((Cidata.ChangeInitiationOwner.toString() === username.toString() || Cidata.DMCMember.toString() === username.toString()) && (RevisionNo >= 1) && (NavigationPath != "/approvedrequest")) ? false : true} onChange={(e) => { setR1_Cost(e.target.value); validateNumber(e.target.value, "R1_Cost") }} />
                                            </td>
                                            <td> <Form.Control type="number" id="R2_Cost" value={R2_Cost} name="R2_Cost" disabled={((Cidata.ChangeInitiationOwner.toString() === username.toString() || Cidata.DMCMember.toString() === username.toString()) && (RevisionNo >= 2) && (NavigationPath != "/approvedrequest")) ? false : true} onChange={(e) => { setR2_Cost(e.target.value); validateNumber(e.target.value, "R2_Cost") }} />
                                            </td>
                                            <td> <Form.Control type="number" id="R3_Cost" value={R3_Cost} name="R3_Cost" disabled={((Cidata.ChangeInitiationOwner.toString() === username.toString() || Cidata.DMCMember.toString() === username.toString()) && (RevisionNo >= 3) && (NavigationPath != "/approvedrequest")) ? false : true} onChange={(e) => { setR3_Cost(e.target.value); validateNumber(e.target.value, "R3_Cost") }} />
                                            </td>
                                            <td> <Form.Control type="number" id="R4_Cost" value={R4_Cost} name="R4_Cost" disabled={((Cidata.ChangeInitiationOwner.toString() === username.toString() || Cidata.DMCMember.toString() === username.toString()) && (RevisionNo >= 4) && (NavigationPath != "/approvedrequest")) ? false : true} onChange={(e) => { setR4_Cost(e.target.value); validateNumber(e.target.value, "R4_Cost") }} />
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                                <p><span class="font-weight-light">*Enter approved values for prior changes (If Applicable) and estimated values for present change</span></p>
                            </Row>
                            <Row><br></br></Row>
                            <Row>
                                <div>
                                    <div class="float-start m-1"> <Button class="btn-primary" style={{ float: "left" }} size="sm"
                                        onClick={(e) => { chanegEvent("ChangeInitiation") }} > Prev </Button> </div>
                                    <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "right" }} size="sm"
                                        onClick={(e) => { chanegEvent("ChangeApproval1") }} > Next </Button> </div>
                                </div>
                            </Row>
                        </Tab>
                        <Tab eventKey="ChangeApproval1" disabled title="Change Approval-1">
                            <Row>
                                <div>
                                    <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "right" }} size="sm"
                                        onClick={(e) => { chanegEvent("ChangeApproval2") }} > Next </Button> </div>
                                    <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "left" }} size="sm"
                                        onClick={(e) => { chanegEvent("TimeAndCost") }} > Prev </Button> </div>

                                </div>
                            </Row>
                            <h5> <span class="text-primary">Change Approval 1</span></h5>
                            <Row>
                                <Col>

                                    <Form.Group className="mb-3">
                                        <Form.Label class="required-field"> Change Feasibility inputs from relevant Stakeholders</Form.Label>
                                        {/* <Form.Control type="text" id="ChangeFesibility_releventStakeholders" value={Cidata.ChangeFesibility_releventStakeholders} disabled name="ChangeFesibility_releventStakeholders" /> */}
                                        <Form.Select aria-label="ChangeFesibility_releventStakeholders" name="ChangeFesibility_releventStakeholders" disabled={Cidata.PendingWithInitiator !== "Yes" ? true : false} id="ChangeFesibility_releventStakeholders" value={ChangeFesibility_releventStakeholders} onChange={(e) => { setChangeFesibility_releventStakeholders(e.target.value); ChangeFeasibilityDrpdownChange() }} >
                                            {YesNoOptions.map((item, index) => {
                                                return <option key={index} value={item.value}>{item.label}</option>
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label> Godrej Construction Sections Team  &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</Form.Label>
                                        <Form.Control type="text" id="constructionSectionTeamtxt" value={Cidata.GodrejSectionDivisions} disabled name="constructionSectionTeam" />
                                        <Form.Select aria-label="constructionSectionTeam" disabled={Cidata.PendingWithInitiator !== "Yes" ? true : ChangeFesibility_releventStakeholders == "Yes" ? false : true} id="constructionSectionTeam" name="constructionSectionTeam" value={tmpGodrejConstructionSections} onChange={(e) => { settmpGodrejConstructionSections(e.target.value); getSectionEmailOptions() }} >
                                            {ConstructionSectionTeamOptions.map((item, index) => {
                                                return <option key={index} value={item.SectionTeamValue}>{item.SectionTeam}</option>
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label> Godrej Construction Sections Email Id  &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</Form.Label>
                                        <Form.Control type="text" id="constructionSectionEmailtxt" value={Cidata.GodrejConstructionSections} disabled name="constructionSectionEmail" />
                                        <Form.Select aria-label="constructionSectionEmail" id="constructionSectionEmail" disabled={Cidata.PendingWithInitiator !== "Yes" ? true : ChangeFesibility_releventStakeholders.toUpperCase() == "YES" ? false : true} name="constructionSectionEmail" value={tmpGodrejConstructionSectionsEmail} onChange={(e) => { settmpGodrejConstructionSectionsEmail(e.target.value); addsectionArray(e.target.value) }} >
                                            {GodrejConstructionSectionsEmailOptions.map((item, index) => {
                                                return <option key={index} value={item.SMEmailIDValue}>{item.SMEmailID}</option>
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row class={SelectedConstructionSection.length == 0 ? "invisible" : "visible"}>
                                <Table id="sectiontable"  >
                                    <thead className="table-head">
                                        <tr>
                                            <th>SectionTeam</th>
                                            <th>SectionEmail</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-body">
                                        {SelectedConstructionSection.map((item, index) =>
                                            <tr key={index}>
                                                <td>{item.SectionTeam}</td>
                                                <td>{item.SectionEmail}</td>
                                                <td>
                                                    <Button size="sm" variant="outline-danger" value={item.SectionEmail} onClick={(e) => removesectionArray(e.target.value)} > Remove </Button>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </Row>
                            <Row><br></br></Row>
                            <Row id="TimeCosttarget">
                                <h5> <span class="text-primary">Time & Cost target</span></h5>
                                <Row>
                                    <table class="table table-small">
                                        <thead className="table-head">
                                            <tr>
                                                <th></th>
                                                <th>Original Commitment</th>
                                                <th>Last Approved Version</th>
                                                <th>New Proposed Commitment</th>
                                                <th>Impact to End Date/Budget(Y/N)</th>
                                                <th>Revised End Date/Budget(if applicable)</th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-body">
                                            <tr>
                                                <td>   <Form.Label>Time</Form.Label></td>
                                                <td> <Form.Control type="text" id="Original_time" disabled value={Original_time} name="Original_time" />
                                                </td>
                                                <td> <Form.Control type="text" id="LastApproved_Time" value={LastApproved_Time} disabled name="LastApproved_Time" />
                                                </td>
                                                <td> <Form.Control type="text" id="NewProposed_time" value={NewProposed_time} disabled name="NewProposed_time" />
                                                </td>
                                                <td> <Form.Group className="mb-3" id="stage1" >
                                                    <Row> <Col></Col>
                                                        <Col><Form.Check type="switch" id="sw_Impact_EndDate" disabled={(Cidata.PDASpocName === username && ((Cidata.StopWork !== "Yes") && StopWork.toUpperCase() !== "YES") && Cidata.ApprovedByDmc === "Yes" && ((Cidata.ChangeFesibility_releventStakeholders === "No") || (Cidata.CountOfStakeHolders.toString() === FeasibilityCount.toString()))) ? false : true} onChange={(e) => { onImpactTimeSwitchAction(e.target.value) }}></Form.Check></Col>
                                                        <Col><Form.Label className="FeedbackLabel" >{Impact_EndDate}</Form.Label></Col>
                                                        <Col></Col>
                                                    </Row></Form.Group>
                                                </td>
                                                <td>
                                                    <Form.Control type="date" id="RevisedEndDate" value={RevisedEndDate} name="RevisedEndDate" disabled={(Cidata.PDASpocName === username && ((Cidata.StopWork !== "Yes") && StopWork.toUpperCase() !== "YES") && Cidata.ApprovedByDmc === "Yes" && ((Cidata.ChangeFesibility_releventStakeholders === "No") || (Cidata.CountOfStakeHolders.toString() === FeasibilityCount.toString()))) ? false : true} onChange={(e) => { setRevisedEndDate(e.target.value) }} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td> <Form.Label > Cost</Form.Label></td>
                                                <td> <Form.Control type="number" id="Original_Cost" value={Original_Cost} disabled name="Original_Cost" />
                                                </td>
                                                <td> <Form.Control type="number" id="LastApprovedCost" value={LastApprovedCost} disabled name="LastApprovedCost" />
                                                </td>
                                                <td> <Form.Control type="number" id="NewProposed_Cost" value={NewProposed_Cost} disabled name="NewProposed_Cost" />
                                                </td>
                                                <td>
                                                    <Form.Group className="mb-3" id="stage1" ><Row><Col></Col>
                                                        <Col><Form.Check type="switch" id="sw_Impact_Budget" disabled={(Cidata.PDASpocName === username && ((Cidata.StopWork !== "Yes") && StopWork.toUpperCase() !== "YES") && Cidata.ApprovedByDmc === "Yes" && ((Cidata.ChangeFesibility_releventStakeholders === "No") || (Cidata.CountOfStakeHolders.toString() === FeasibilityCount.toString()))) ? false : true} onChange={(e) => { onImpactBudgetSwitchAction(e.target.value) }}></Form.Check></Col>
                                                        <Col><Form.Label className="FeedbackLabel" >{Impact_Budget}</Form.Label></Col>
                                                        <Col></Col></Row></Form.Group></td>
                                                <td>
                                                    <Form.Control type="number" value={RevisedBudget} id="RevisedBudget" disabled={(Cidata.PDASpocName === username && ((Cidata.StopWork !== "Yes") && StopWork.toUpperCase() !== "YES") && Cidata.ApprovedByDmc === "Yes" && ((Cidata.ChangeFesibility_releventStakeholders === "No") || (Cidata.CountOfStakeHolders.toString() === FeasibilityCount.toString()))) ? false : true} name="RevisedBudget" onChange={(e) => { setRevisedBudget(e.target.value); validateNumber(e.target.value, "RevisedBudget") }} />

                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>

                                </Row>
                                <Row><br></br></Row>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label > Other G&B Divisions (including End User)</Form.Label>
                                        {/* <Form.Control type="text" id="OtherDivision" value={Cidata.OtherDivision} disabled name="OtherDivision" /> */}
                                        <Form.Select aria-label="OtherDivision" id="OtherDivision" name="OtherDivision" disabled={Cidata.PendingWithInitiator !== "Yes" ? true : false} value={OtherDivision} onChange={(e) => { setOtherDivision(e.target.value); getOtherDivisionEmailOptions() }} >
                                            {OtherDivisionOptions.map((item, index) => {
                                                return <option key={index} value={item.Division}>{item.Division}</option>
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label > Other Divisions Email Id</Form.Label>
                                        {/* <Form.Control type="text" id="OtherDivisionEmail" value={Cidata.Other} disabled name="OtherDivisionEmail" /> */}
                                        <Form.Select aria-label="OtherDivisionEmail" id="OtherDivisionEmail" name="OtherDivisionEmail" disabled={(Cidata.PendingWithInitiator !== "Yes" && (OtherDivision.toUpperCase() !== "SELECT" || OtherDivision.toUpperCase() !== "")) ? true : false} value={OtherDivisionEmail} onChange={(e) => setOtherDivisionEmail(e.target.value)} >
                                            {OtherDivisionEmailOptions.map((item, index) => {
                                                return <option key={index} value={item.EmailId}>{item.EmailId}</option>
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>

                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label class="required-field"> PDA SPOC</Form.Label>
                                        <Form.Select aria-label="pdaspoc" id="pdaspoc" name="pdaspoc" disabled={(Cidata.PendingWithInitiator !== "Yes") ? true : false} value={PDASpocName} onChange={(e) => setPDASpocName(e.target.value)} >
                                            {PDASpocNameOptions.map((item, index) => {
                                                return <option key={index} value={item.PDAname}>{item.PDAname}</option>
                                            })}
                                        </Form.Select>
                                        {/* <Form.Control type="text" id="pdaspoc" value={Cidata.PDASpocName} disabled name="pdaspoc" onChange={(e) => setPDASpocName(e.target.value)} /> */}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label class="required-field"> Send to Finance team?</Form.Label>
                                        {/* <Form.Control type="text" id="IsFinance" value={Cidata.IsFinance} disabled name="IsFinance" /> */}
                                        <Form.Select name="IsFinance" id="IsFinance" value={IsFinance} disabled={(Cidata.PendingWithInitiator !== "Yes") ? true : false} onChange={(e) => setIsFinance(e.target.value)} >
                                            {YesNoOptions.map((item, index) => {
                                                return <option key={index} value={item.value}>{item.label}</option>
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Finance Team</Form.Label>
                                        {/* <Form.Control type="text" id="financeteam" value={Cidata.FinanceMemberName} disabled name="financeteam" /> */}
                                        <Form.Select aria-label="financeteam" id="financeteam" name="financeteam" disabled={IsFinance.toUpperCase() === "YES" && Cidata.PendingWithInitiator === "Yes" ? false : true} value={FinanceMemberName} onChange={(e) => setFinanceMemberName(e.target.value)} >
                                            {FinanceMemberNameOptions.map((item, index) => {
                                                return <option key={index} value={item.Fname}>{item.Fname}</option>
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label class="required-field"> All Feasibility Reports annexed and their impacts considered in the change request: (Yes / No):</Form.Label>
                                        {/* <Form.Control type="text" id="ChangeFeasibilityAnnexed" value={Cidata.ChangeFeasibilityAnnexed} disabled name="ChangeFeasibilityAnnexed" /> */}
                                        <Form.Select name="feasibilityannexed" id="ChangeFeasibilityAnnexed" disabled={(Cidata.PendingWithInitiator !== "Yes") ? true : false} value={ChangeFeasibilityAnnexed} onChange={(e) => setChangeFeasibilityAnnexed(e.target.value)} >
                                            {YesNoOptions.map((item, index) => {
                                                return <option key={index} value={item.value}>{item.label}</option>
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row><br></br></Row>
                            <Row>
                                <div>
                                    <div class="float-start m-1"> <Button class="btn-primary" style={{ float: "left" }} size="sm"
                                        onClick={(e) => { chanegEvent("TimeAndCost") }} > Prev </Button> </div>
                                    <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "right" }} size="sm"
                                        onClick={(e) => { chanegEvent("ChangeApproval2") }} > Next </Button> </div>
                                </div>
                            </Row>

                        </Tab>
                        <Tab eventKey="ChangeApproval2" disabled title="Change Approval-2">
                            <Row>
                                <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "right" }} size="sm"
                                    onClick={(e) => { chanegEvent("ChangeApproval1") }} > Prev </Button> </div>

                            </Row>
                            <h5> <span class="text-primary">Change Approval 2</span></h5>
                            <Form.Group className="mb-3">
                                <Row>
                                    <Form.Label className="FeedbackLabel" >Stop Work</Form.Label>

                                    <Col> <Form.Check
                                        type="switch"
                                        id="stopwork"
                                        onChange={onSwitchAction}

                                    /></Col>
                                    {/* <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Stop work</Form.Label>
                                <Form.Select name="StopWork" id="StopWork" value={StopWork} onChange={(e) => setStopWork(e.target.value)} >                                   
                                     <option value="No">No</option>
                                     <option value="Yes">Yes</option>
                                   
                                </Form.Select>
                            </Form.Group>
                        </Col> */}
                                    <Col>
                                        <Form.Label>If Yes, Number of Days</Form.Label>
                                        <Form.Control type="number" name="stopworkdays" id="NoofDays" required={StopWork === "No" ? true : false} value={NoofDays} disabled={StopWork !== "Yes" ? true : false} onChange={(e) => { setNoofDays(e.target.value); validateNumberofDays(e.target.value) }} />
                                    </Col>
                                </Row>
                                <span class="text-primary">Change Initiation</span>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Change Initiated By</Form.Label>
                                            <Form.Control type="text" value={Cidata.ChangeInitiationBy} disabled="true" name="changeInitiator" ></Form.Control>

                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Change Initiated On</Form.Label>
                                            <Form.Control type="text" value={Cidata.ChangeInitiationOn} name="ChangeInitiationOn" disabled="true"  ></Form.Control>

                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label class="required-field"> Stop work approved by DMC</Form.Label>
                                            <Form.Select name="stopworkapprovedbydmc" id="StopWorkApprovedByDMC" value={StopWorkApprovedByDMC} disabled={(Cidata.DMCMember === username && (Cidata.StopWork === "Yes") && (StopWork.toUpperCase() === "YES") && Cidata.StopWorkApprovedByPda !== "Yes" && Cidata.StopWorkApprovedByDMC !== "Yes" && Cidata.IsStopWorkPendingWithInitiator !== "Yes") ? false : true} onChange={(e) => setStopWorkApprovedByDMC(e.target.value)} >
                                                {YesNoOptions.map((item, index) => {
                                                    return <option key={index} value={item.value}>{item.label}</option>
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>DMC Member</Form.Label>
                                            <Form.Control type="text" id="DMCMemberEmail" value={Cidata.DMCMemberEmail} disabled="true" name="DMCMember" />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Date</Form.Label>
                                            <Form.Control type="text" id="StopWorkApprovedByDmcOn" value={(Cidata.DMCMember === username && (StopWork.toUpperCase() === "YES") && Cidata.StopWorkApprovedByPda !== "Yes" && Cidata.StopWorkApprovedByDMC !== "Yes" && Cidata.IsStopWorkPendingWithInitiator !== "Yes") ? todaysdate : Cidata.StopWorkApprovedByDmcOn} name="StopWorkApprovedByDmcOn" onChange={(e) => setStopWorkApprovedByDmcOn(e.target.value)} disabled="true" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <span class="text-primary">Temporary Stop Work (If Applicable)</span>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label class="required-field"> Stop work Approved by Client / Client SPOC</Form.Label>
                                            <Form.Select name="stopworkapprovedbyPda" id="StopWorkApprovedByPda" value={StopWorkApprovedByPda} disabled={(Cidata.PDAStopWorkApprover === useremail && (StopWork.toUpperCase() === "YES") && Cidata.StopWorkApprovedByPda !== "Yes" && Cidata.StopWorkApprovedByDMC === "Yes" && Cidata.StopWorkApprovedByGC !== "Yes" && Cidata.IsStopWorkPendingWithInitiator !== "Yes") ? false : true} onChange={(e) => setStopWorkApprovedByPda(e.target.value)}  >
                                                {YesNoOptions.map((item, index) => {
                                                    return <option key={index} value={item.value}>{item.label}</option>
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Approver</Form.Label>
                                            <Form.Control type="text" id="PDAStopWorkApprover" value={Cidata.PDAStopWorkApprover} disabled="true" name="PdaHead" onChange={(e) => setPDAStopWorkApprover(e.target.value)} />

                                        </Form.Group>
                                    </Col>

                                </Row>
                                <Row>

                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Remarks</Form.Label>
                                            <Form.Control as="textarea" id="PdastopworkapprovalRemarks" value={PdastopworkapprovalRemarks} name="PdastopworkapprovalRemarks" disabled={(Cidata.PDAStopWorkApprover === useremail && (StopWork.toUpperCase() === "YES") && Cidata.StopWorkApprovedByPda !== "Yes" && Cidata.StopWorkApprovedByDMC === "Yes" && Cidata.StopWorkApprovedByGC !== "Yes" && Cidata.IsStopWorkPendingWithInitiator !== "Yes") ? false : true} onChange={(e) => setPdastopworkapprovalRemarks(e.target.value)} />

                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Date</Form.Label>
                                            <Form.Control type="text" id="StopWorkApprovedByPdaOn" value={(Cidata.PDAStopWorkApprover === useremail && (StopWork.toUpperCase() === "YES") && Cidata.StopWorkApprovedByPda !== "Yes" && Cidata.StopWorkApprovedByDMC === "Yes" && Cidata.StopWorkApprovedByGC !== "Yes" && Cidata.IsStopWorkPendingWithInitiator !== "Yes") ? todaysdate : Cidata.StopWorkApprovedByPdaOn} name="StopWorkApprovedByPdaOn" onChange={(e) => setStopWorkApprovedByPdaOn(e.target.value)} disabled="true" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label class="required-field"> Stop work Approved by GC Business Head</Form.Label>
                                            <Form.Select name="StopWorkApprovedByGC" id="StopWorkApprovedByGC" value={StopWorkApprovedByGC} disabled={(GCStopWorkApprover === useremail && (StopWork.toUpperCase() === "YES") && Cidata.StopWorkApprovedByPda === "Yes" && Cidata.StopWorkApprovedByGC !== "Yes" && Cidata.IsStopWorkPendingWithInitiator !== "Yes") ? false : true} onChange={(e) => setStopWorkApprovedByGC(e.target.value)}  >
                                                {YesNoOptions.map((item, index) => {
                                                    return <option key={index} value={item.value}>{item.label}</option>
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Approver</Form.Label>
                                            <Form.Control type="text" id="GCStopWorkApprover" value={Cidata.GCStopWorkApprover} disabled="true" name="GCStopWorkApprover" onChange={(e) => setGCStopWorkApprover(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Remarks</Form.Label>
                                            <Form.Control as="textarea" id="GCstopworkapprovalRemarks" value={GCstopworkapprovalRemarks} name="GCstopworkapprovalRemarks" disabled={(Cidata.GCStopWorkApprover === useremail && (StopWork.toUpperCase() === "YES") && Cidata.StopWorkApprovedByPda === "Yes" && Cidata.StopWorkApprovedByGC !== "Yes" && Cidata.IsStopWorkPendingWithInitiator !== "Yes") ? false : true} onChange={(e) => setGCstopworkapprovalRemarks(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Date</Form.Label>
                                            <Form.Control type="text" id="GCStopWorkApproveDate" value={(GCStopWorkApprover === useremail && (StopWork.toUpperCase() === "YES") && Cidata.StopWorkApprovedByPda === "Yes" && Cidata.StopWorkApprovedByGC !== "Yes" && Cidata.IsStopWorkPendingWithInitiator !== "Yes") ? todaysdate : Cidata.GCStopWorkApproveDate} name="GCStopWorkApproveDate" disabled="true" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <span class="text-primary">Change Approval</span>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label class="required-field"> Approved By DMC Of Initiator</Form.Label>
                                            <Form.Select name="ApprovedByDmc" value={ApprovedByDmc} disabled={(Cidata.DMCMember.toUpperCase() === username.toUpperCase() && ((Cidata.StopWork !== "Yes") && StopWork.toUpperCase() !== "YES") && StopWork.toUpperCase() !== "YES" && Cidata.ApprovedByPDATeam !== "Yes" && Cidata.ApprovedByFinance !== "Yes" && Cidata.ApprovedByDmc !== "Yes" && Cidata.PendingWithInitiator !== "Yes") ? false : true} id="ApprovedByDmc" onChange={(e) => setApprovedByDmc(e.target.value)}   >
                                                {YesNoOptions.map((item, index) => {
                                                    return <option key={index} value={item.value}>{item.label}</option>
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Approver</Form.Label>
                                            <Form.Control type="text" id="DMCMember" value={Cidata.DMCMember} disabled="true" name="Dmcmember" onChange={(e) => setDMCMember(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>

                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Remarks</Form.Label>
                                            <Form.Control as="textarea" id="RemarksforRejection" value={RemarksforRejection} name="RemarksforRejection" disabled={(Cidata.DMCMember === username && ((Cidata.StopWork !== "Yes") && StopWork.toUpperCase() !== "YES") && StopWork.toUpperCase() !== "YES" && Cidata.ApprovedByPDATeam !== "Yes" && Cidata.ApprovedByFinance !== "Yes" && Cidata.ApprovedByDmc !== "Yes" && Cidata.PendingWithInitiator !== "Yes") ? false : true} onChange={(e) => setRemarksforRejection(e.target.value)} />

                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Date</Form.Label>
                                            <Form.Control type="text" id="ApprovedByDmcDate" value={(Cidata.DMCMember === username && ((Cidata.StopWork !== "Yes") && StopWork.toUpperCase() !== "YES") && StopWork.toUpperCase() !== "YES" && Cidata.ApprovedByPDATeam !== "Yes" && Cidata.ApprovedByFinance !== "Yes" && Cidata.ApprovedByDmc !== "Yes" && Cidata.PendingWithInitiator !== "Yes") ? todaysdate : Cidata.ApprovedByDmcDate} name="ApprovedByDmcDate" disabled="true" />

                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label class="required-field"> Approved By PDA Team</Form.Label>
                                            <Form.Select name="ApprovedByPDATeam" id="ApprovedByPDATeam" value={ApprovedByPDATeam} disabled={(Cidata.PDASpocName === username && ((Cidata.StopWork !== "Yes") && StopWork.toUpperCase() !== "YES") && Cidata.ApprovedByPDATeam !== "Yes" && Cidata.ApprovedByDmc === "Yes" && ((Cidata.ChangeFesibility_releventStakeholders === "No") || (Cidata.CountOfStakeHolders.toString() === FeasibilityCount.toString())) && Cidata.PendingWithInitiator !== "Yes") ? false : true} onChange={(e) => setApprovedByPDATeam(e.target.value)}  >
                                                {YesNoOptions.map((item, index) => {
                                                    return <option key={index} value={item.value}>{item.label}</option>
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Approver</Form.Label>
                                            <Form.Control type="text" id="PDASpocName" value={Cidata.PDASpocName} disabled="true" name="PDASpocName" onChange={(e) => setPDASpocName(e.target.value)} />

                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>

                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Remarks</Form.Label>
                                            <Form.Control as="textarea" id="RemarkByPDA" value={RemarkByPDA} name="RemarkByPDA" disabled={(Cidata.PDASpocName === username && ((Cidata.StopWork !== "Yes") && StopWork.toUpperCase() !== "YES") && Cidata.ApprovedByPDATeam !== "Yes" && Cidata.ApprovedByDmc === "Yes" && ((Cidata.ChangeFesibility_releventStakeholders === "No") || (Cidata.CountOfStakeHolders.toString() === FeasibilityCount.toString())) && Cidata.PendingWithInitiator !== "Yes") ? false : true} onChange={(e) => setRemarkByPDA(e.target.value)} />

                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Date</Form.Label>
                                            <Form.Control type="text" id="ApprovedByPDATeamOn" value={(Cidata.PDASpocName === username && ((Cidata.StopWork !== "Yes") && StopWork.toUpperCase() !== "YES") && StopWork.toUpperCase() !== "YES" && Cidata.ApprovedByPDATeam !== "Yes" && Cidata.ChangeAcceptedbyDMC !== "Yes" && ((Cidata.ChangeFesibility_releventStakeholders === "No") || (Cidata.CountOfStakeHolders.toString() === FeasibilityCount.toString())) && Cidata.ApprovedByDmc === "Yes" && Cidata.PendingWithInitiator !== "Yes") ? todaysdate : Cidata.ApprovedByPDATeamOn} name="ApprovedByPDATeamOn" disabled="true" />

                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label class="required-field"> Approved By Finance Team</Form.Label>
                                            <Form.Select name="ApprovedByFinance" id="ApprovedByFinance" value={ApprovedByFinance} disabled={(Cidata.FinanceApprover === username && ((Cidata.StopWork !== "Yes") && StopWork.toUpperCase() !== "YES") && StopWork.toUpperCase() !== "YES" && Cidata.ApprovedByFinance.toString() !== "Yes" && Cidata.ChangeAcceptedbyDMC.toString() !== "Yes" && ((Cidata.ChangeFesibility_releventStakeholders.toString() === "No") || (Cidata.CountOfStakeHolders.toString() === FeasibilityCount.toString())) && Cidata.ApprovedByDmc.toString() === "Yes" && Cidata.PendingWithInitiator !== "Yes") ? false : true} onChange={(e) => setApprovedByFinance(e.target.value)}  >
                                                {YesNoOptions.map((item, index) => {
                                                    return <option key={index} value={item.value}>{item.label}</option>
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Approver</Form.Label>
                                            <Form.Control type="text" id="FinanceApprover" value={Cidata.FinanceApprover} disabled="true" name="FinanceApprover" onChange={(e) => setFinanceApprover(e.target.value)} />

                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>

                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Remarks</Form.Label>
                                            <Form.Control as="textarea" id="RemarkByFinance" value={RemarkByFinance} name="RemarkByFinance" disabled={(Cidata.FinanceApprover === username && ((Cidata.StopWork !== "Yes") && StopWork.toUpperCase() !== "YES") && StopWork.toUpperCase() !== "YES" && Cidata.ApprovedByFinance.toString() !== "Yes" && Cidata.ChangeAcceptedbyDMC.toString() !== "Yes" && ((Cidata.ChangeFesibility_releventStakeholders.toString() === "No") || (Cidata.CountOfStakeHolders.toString() === FeasibilityCount.toString())) && Cidata.ApprovedByDmc.toString() === "Yes" && Cidata.PendingWithInitiator !== "Yes") ? false : true} onChange={(e) => setRemarkByFinance(e.target.value)} />

                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Date</Form.Label>
                                            <Form.Control type="text" id="ApprovedByFinanceOn" value={(Cidata.FinanceApprover === username && ((Cidata.StopWork !== "Yes") && StopWork.toUpperCase() !== "YES") && StopWork.toUpperCase() !== "YES" && Cidata.ApprovedByFinance.toString() !== "Yes" && Cidata.ChangeAcceptedbyDMC.toString() !== "Yes" && ((Cidata.ChangeFesibility_releventStakeholders.toString() === "No") || (Cidata.CountOfStakeHolders.toString() === FeasibilityCount.toString())) && Cidata.ApprovedByDmc.toString() === "Yes" && Cidata.PendingWithInitiator !== "Yes") ? todaysdate : Cidata.ApprovedByFinanceOn} name="ApprovedByFinanceOn" disabled="true" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label class="required-field"> Change Accepted by DMC Member heading project / initiative OR BH / SPOC of Other Division seeking change</Form.Label>
                                            <Form.Select name="ChangeAcceptedbyDMC" id="ChangeAcceptedbyDMC" value={ChangeAcceptedbyDMC} disabled={(Cidata.DMCMemberOwner === useremail && ((Cidata.StopWork !== "Yes") && StopWork.toUpperCase() !== "YES") && StopWork.toUpperCase() !== "YES" && Cidata.ApprovedByPDATeam === "Yes" && (((Cidata.IsFinance === "Yes" && Cidata.ApprovedByFinance !== "Yes") || (Cidata.IsFinance !== "Yes") ? "Yes" : "No") === "Yes") && Cidata.ChangeAcceptedbyDMC !== "Yes" && Cidata.ApprovedByPDA !== "Yes" && Cidata.PendingWithInitiator !== "Yes") ? false : true} onChange={(e) => setChangeAcceptedbyDMC(e.target.value)}  >
                                                {YesNoOptions.map((item, index) => {
                                                    return <option key={index} value={item.value}>{item.label}</option>
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Approver</Form.Label>
                                            <Form.Control type="text" id="DMCMemberOwner" value={Cidata.DMCMemberOwner} disabled="true" name="ProjDMCName" onChange={(e) => setProjDMCName(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>

                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Remarks</Form.Label>
                                            <Form.Control as="textarea" id="RemarkByProjDmc" value={RemarkByProjDmc} name="RemarkByProjDmc" disabled={(Cidata.DMCMemberOwner === useremail && ((Cidata.StopWork !== "Yes") && StopWork.toUpperCase() !== "YES") && StopWork.toUpperCase() !== "YES" && Cidata.ApprovedByPDATeam === "Yes" && (((Cidata.IsFinance === "Yes" && Cidata.ApprovedByFinance !== "Yes") || (Cidata.IsFinance !== "Yes") ? "Yes" : "No") === "Yes") && Cidata.ChangeAcceptedbyDMC !== "Yes" && Cidata.ApprovedByPDA !== "Yes" && Cidata.PendingWithInitiator !== "Yes") ? false : true} onChange={(e) => setRemarkByProjDmc(e.target.value)} />

                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Date</Form.Label>
                                            <Form.Control type="text" id="ProjDMCAcceptanceDate" value={(Cidata.DMCMemberOwner === useremail && ((Cidata.StopWork !== "Yes") && StopWork.toUpperCase() !== "YES") && StopWork.toUpperCase() !== "YES" && Cidata.ApprovedByPDATeam === "Yes" && (((Cidata.IsFinance === "Yes" && Cidata.ApprovedByFinance !== "Yes") || (Cidata.IsFinance !== "Yes") ? "Yes" : "No") === "Yes") && Cidata.ChangeAcceptedbyDMC !== "Yes" && Cidata.ApprovedByPDA !== "Yes" && Cidata.PendingWithInitiator !== "Yes") ? todaysdate : Cidata.ProjDMCAcceptanceDate} name="ProjDMCAcceptanceDate" disabled="true" />

                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label class="required-field"> Change Timeline Validated by P&DA</Form.Label>
                                            <Form.Select name="ChangeValidatedByPDA" id="ChangeValidatedByPDA" value={ChangeValidatedByPDA} disabled={(Cidata.PDAApprover === username && ((Cidata.StopWork !== "Yes") && StopWork.toUpperCase() !== "YES") && StopWork.toUpperCase() !== "YES" && Cidata.ChangeAcceptedbyDMC === "Yes" && Cidata.ChangeApprovedbyGC !== "Yes" && Cidata.ChangeValidatedByPDA !== "Yes" && Cidata.PendingWithInitiator !== "Yes") ? false : true} onChange={(e) => setChangeValidatedByPDA(e.target.value)}   >
                                                {YesNoOptions.map((item, index) => {
                                                    return <option key={index} value={item.value}>{item.label}</option>
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Approver</Form.Label>
                                            <Form.Control type="text" id="PDAApprover" value={Cidata.PDAApprover} disabled="true" name="PDAApprover" onChange={(e) => setPDAApprover(e.target.value)} />

                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>

                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Remarks</Form.Label>
                                            <Form.Control as="textarea" id="RemarkByPdaHead" value={RemarkByPdaHead} name="RemarkByPdaHead" disabled={(Cidata.PDAApprover === username && ((Cidata.StopWork !== "Yes") && StopWork.toUpperCase() !== "YES") && StopWork.toUpperCase() !== "YES" && Cidata.ChangeAcceptedbyDMC === "Yes" && Cidata.ChangeApprovedbyGC !== "Yes" && Cidata.ChangeValidatedByPDA !== "Yes" && Cidata.PendingWithInitiator !== "Yes") ? false : true} onChange={(e) => setRemarkByPdaHead(e.target.value)} />

                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Date</Form.Label>
                                            <Form.Control type="text" id="ApprovedByPdaOn" value={(Cidata.PDAApprover === username && ((Cidata.StopWork !== "Yes") && StopWork.toUpperCase() !== "YES") && StopWork.toUpperCase() !== "YES" && Cidata.ChangeAcceptedbyDMC === "Yes" && Cidata.ChangeApprovedbyGC !== "Yes" && Cidata.ChangeValidatedByPDA !== "Yes" && Cidata.PendingWithInitiator !== "Yes") ? todaysdate : Cidata.ApprovedByPdaOn} name="ApprovedByPdaOn" disabled="true" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label class="required-field"> Change Approved by GC Business Head</Form.Label>
                                            <Form.Select name="ChangeApprovedbyGC" id="ChangeApprovedbyGC" value={ChangeApprovedbyGC} disabled={(Cidata.GCApprover === username && ((Cidata.StopWork !== "Yes") && StopWork.toUpperCase() !== "YES") && StopWork.toUpperCase() !== "YES" && Cidata.ChangeValidatedByPDA === "Yes" && Cidata.ChangeApprovedbyGC !== "Yes" && Cidata.PendingWithInitiator !== "Yes") ? false : true} onChange={(e) => setChangeApprovedbyGC(e.target.value)} >
                                                {YesNoOptions.map((item, index) => {
                                                    return <option key={index} value={item.value}>{item.label}</option>
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Approver</Form.Label>
                                            <Form.Control type="text" id="GCApprover" value={Cidata.GCApprover} disabled="true" name="GCApprover" onChange={(e) => setGCApprover(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Remarks</Form.Label>
                                            <Form.Control as="textarea" id="RemarkByBH" value={RemarkByBH} name="RemarkByBH" disabled={(Cidata.GCApprover === username && ((Cidata.StopWork !== "Yes") && StopWork.toUpperCase() !== "YES") && StopWork.toUpperCase() !== "YES" && Cidata.ChangeValidatedByPDA === "Yes" && Cidata.ChangeApprovedbyGC !== "Yes" && Cidata.PendingWithInitiator !== "Yes") ? false : true} onChange={(e) => setRemarkByBH(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Date</Form.Label>
                                            <Form.Control type="text" id="GCHeadApprovalDate" value={(Cidata.GCApprover === username && ((Cidata.StopWork !== "Yes") && StopWork.toUpperCase() !== "YES") && StopWork.toUpperCase() !== "YES" && Cidata.ChangeValidatedByPDA === "Yes" && Cidata.ChangeApprovedbyGC !== "Yes" && Cidata.PendingWithInitiator !== "Yes") ? todaysdate : Cidata.GCHeadApprovalDate} name="GCHeadApprovalDate" disabled="true" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form.Group>

                            <Container style={{ display: "flex", justifyContent: "center" }}>
                                <Stack direction="horizontal" gap={4}>
                                    <Button variant="success" size="lg" disabled={NavigationPath != "/approvedrequest" ? false : true} onClick={updateData} type="submit">Submit</Button>
                                    <Button variant="danger" size="lg" onClick={btnCancelClick} type="reset">Cancel</Button>
                                </Stack>
                            </Container>
                            <Row><br></br></Row>
                            <Row>
                                <div class="float-start m-1"> <Button class="btn-primary" style={{ float: "left" }} size="sm"
                                    onClick={(e) => { chanegEvent("ChangeApproval1") }} > Prev </Button> </div>

                            </Row>
                        </Tab>
                    </Tabs>
                </Form>
            )}
        </Container>

    )

};



