/* 
Program Name : ChangeAnalysisUploader2.jsx
Written By: Mayur Solanki
Written on: 12 Mar 2022 
System Name: Change Management App
Purpose:  Screen to add change analysis
Type of Program: React Frontend
*/

import React from 'react'
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { FileEarmarkText, Trash } from "react-bootstrap-icons";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Placeholder, Row, Stack } from "react-bootstrap";
import {  
  getAll,getEdmcDetails
} from "../services/edmc-service";
import {
  getApprovalHeads
} from "../services/ApprovalHeads-service";



const ChangeAnalysisUploader2 = () => {

  const navigate = useNavigate();
    
 
  useEffect(() => {
    fetchsetret();
  }, [])

  
  var username =localStorage.getItem('UserName');
  var userEmail = localStorage.getItem('UserId');

  
  const [CorrectiveAction, setCorrectiveAction] = useState("");
  const [PreventiveAction, setPreventiveAction] = useState("");
  const [ProcessUpdationDate, setProcessUpdationDate] = useState(new Date());
  const [Justification, setJustification] = useState("");
  const [TrackingPeriod, setTrackingPeriod] = useState(0);
  const [TrackingEffectivenessEDMCMember, setTrackingEffectivenessEDMCMember] = useState("");
  const [TrackingDMCMember, setTrackingDMCMember] = useState("");
  //Attachment
  const [UploadedFileLocation, setUploadedFileLocation] = useState("");
  const [MultipleFileLocations, setMultipleFileLocations] = useState([]);
  const [FileNames, setFileNames] = useState([]);
  const [ProcessChangeImpact, setProcessChangeImpact] = useState("No");
  
  const [newAttachedFile, setnewAttachedFile] = useState([]);
  const [fileList, setFileList] = useState([]);
 

  const fetchsetret = async () => { 
    
    const resultfromah = await getApprovalHeads();
    
    
    var Qalead = resultfromah[0].ApproverName;
    var QaleadEmail = resultfromah[0].ApproverEmailID;

    var ChangeTimeLinePDA = resultfromah[1].ApproverName
    var ChangeTimeLinePDAEmail = resultfromah[1].ApproverEmailID;

    var GCBusinessHead = resultfromah[2].ApproverName
    var GCBusinessHeadEmail = resultfromah[2].ApproverEmailID;

    sessionStorage.setItem("keyap1", Qalead);
    sessionStorage.setItem("keyap2", ChangeTimeLinePDA);
    sessionStorage.setItem("keyap3", GCBusinessHead);
    sessionStorage.setItem("keyapEmail1", QaleadEmail);
    sessionStorage.setItem("keyapEmail2", ChangeTimeLinePDAEmail);
    sessionStorage.setItem("keyapEmail3", GCBusinessHeadEmail);

    var datahu1 = sessionStorage.getItem("postbackcorrectiveaction")
    if (datahu1 == null || datahu1 == "null") { datahu1 = "" }
    
    setCorrectiveAction(datahu1)
    var datahu2 = sessionStorage.getItem("postbackpreventiveaction")
    if (datahu2 == null || datahu2 == "null") { datahu2 = "" }
    
    setPreventiveAction(datahu2)

    var datahuProcessChangeImpact = sessionStorage.getItem("postbackProcessChangeImpact")
    if (datahuProcessChangeImpact == null || datahuProcessChangeImpact == "null") { datahuProcessChangeImpact = "No" }
    
    setProcessUpdationDate(datahuProcessChangeImpact)

    var datahu3 = sessionStorage.getItem("postbackProcessUpdationDate")
    if (datahu3 == null || datahu3 == "null") { datahu3 = "" }
    
    setProcessUpdationDate(datahu3)
    var datahu4 = sessionStorage.getItem("postbackJustification")
    if (datahu4 == null || datahu4 == "null") { datahu4 = "" }
    
    setJustification(datahu4)
    var datahu5 = sessionStorage.getItem("postbackTrackingPeriod")
    if (datahu5 == null || datahu5 == "null") { datahu5 = "" }
    
    setTrackingPeriod(datahu5)
    var datahu6 = sessionStorage.getItem("postbackTrackingEffectivenessEDMCMember")
    if (datahu6 == null || datahu6 == "null") { datahu6 = "" }
    
    setTrackingEffectivenessEDMCMember(datahu6)
    var datahu7 = sessionStorage.getItem("postbackTrackingDMCMember")
    if (datahu7 == null || datahu7 == "null") { datahu7 = "" }
    
    setTrackingDMCMember(datahu7)

  }




  var datas1 = sessionStorage.getItem("keyImpactTime")
  sessionStorage.setItem("postbackimpacttime", datas1)
  var datasImpactTimeInd = sessionStorage.getItem("keyImpactTimeInd")
  sessionStorage.setItem("postbackImpactTimeInd", datasImpactTimeInd)

  var datas2 = sessionStorage.getItem("keyImpactCost")
    sessionStorage.setItem("postbackimpactcost", datas2)
    var datasImpactCostInd = sessionStorage.getItem("keyImpactCostInd")
    sessionStorage.setItem("postbackImpactCostInd", datasImpactCostInd)

  var datas3 = sessionStorage.getItem("keyImpactQuality")
    sessionStorage.setItem("postbackimpactquality", datas3)
    var dataskeyImpactQualityInd = sessionStorage.getItem("keyImpactQualityInd")
    sessionStorage.setItem("postbackkeyImpactQualityInd", dataskeyImpactQualityInd)

  var datas4 = sessionStorage.getItem("keyImpactOthers")
    sessionStorage.setItem("postbackimpactothers", datas4)
    var dataskeyImpactOthersInd = sessionStorage.getItem("keyImpactOthersInd")
    sessionStorage.setItem("postbackkeyImpactOthersInd", dataskeyImpactOthersInd)

  var datas5 = sessionStorage.getItem("keyImpactSafety")
    sessionStorage.setItem("postbackimpactsafety", datas5)
    
    var dataskeyImpactSafetyInd = sessionStorage.getItem("keyImpactSafetyInd")
    sessionStorage.setItem("postbackkeyImpactSafetyInd", dataskeyImpactSafetyInd)

  var datas6 = sessionStorage.getItem("keyImpactContract")
    sessionStorage.setItem("postbackimpactcontract", datas6)
    
    var dataskeyImpactContractInd = sessionStorage.getItem("keyImpactContractInd")
    sessionStorage.setItem("postbackkeyImpactContractInd", dataskeyImpactContractInd)


  var datas7 = sessionStorage.getItem("keyImpactCompliance")
    sessionStorage.setItem("postbackimpactcompliance", datas7)

    var dataskeyImpactComplianceInd = sessionStorage.getItem("keyImpactComplianceInd")
    sessionStorage.setItem("postbackkeyImpactComplianceInd", dataskeyImpactComplianceInd)

  var datas8 = sessionStorage.getItem('keysesvarcrn1')
  
  sessionStorage.setItem("postbackcrn1", datas8)


  var datas9 = sessionStorage.getItem('keysesvarcio1')
  
  sessionStorage.setItem("postbackcio1", datas9)


  var datas10 = sessionStorage.getItem('keysesvarwt1')
  
  sessionStorage.setItem("postbackwt1", datas10)

  var datas11 = sessionStorage.getItem('keysesvarpn1')
  
  sessionStorage.setItem("postbackpn1", datas11)
  var datas12 = sessionStorage.getItem('keysesvarpnum1')
  
  sessionStorage.setItem("postbackpnum1", datas12)
  var datas13 = sessionStorage.getItem('keysesvarpo1')
  
  sessionStorage.setItem("postbackpo1", datas13)
  var datas14 = sessionStorage.getItem('keysesvardmo1')
  
  sessionStorage.setItem("postbackdmo1", datas14)
  var datas15 = sessionStorage.getItem('keysesvarcrd1')
  
  sessionStorage.setItem("postsbackcrd1", datas15)









  //const axios = require("axios").default;
  

  const handleChangeStatus = ({ meta, remove }, status) => {
    
  }
  const CheckValidEDMCEmail = async () => {  
   let validEmailAlertMessage="";    
    const EDMCResult = await getAll(); 
    let ValidEDMCEmailId=true;
    let ValidDMCEmailId=true;
    
    let EdmcMember=TrackingEffectivenessEDMCMember.split(";");
    let DmcMember=TrackingDMCMember.split(";");
    let EdmcMemberdata = EDMCResult.map(t => t.EDMCMemberEmail); 
    let DmcMemberdata = EDMCResult.map(t => t.DMCofEDMC);   
  
    for (let i = 0; i < EdmcMember.length; i++) {   
     if(!(EdmcMemberdata.includes(EdmcMember[i].trim()))){
      ValidEDMCEmailId=false;       
     }   
   }
   for (let i = 0; i < DmcMember.length; i++) {   
    if(!(DmcMemberdata.includes(DmcMember[i].trim()))){
      ValidDMCEmailId=false;   
    } 
  }
  if(ValidEDMCEmailId==false){
    validEmailAlertMessage+="Entered EDMC Member is Not Valid or same is not maintained in database \r\n";
  }
  if(ValidDMCEmailId==false){
    validEmailAlertMessage+="Entered DMC Member is Not Valid or same is not maintained in database \r\n";
  }
  return validEmailAlertMessage;
 } ; 
     

  function chkValidation(e) {  
    let alertmsg="";   
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;     
    
     if(sessionStorage.getItem("keysFileNames")=="" ||sessionStorage.getItem("keysFileNames")== null ){
       alertmsg+="Please Upload File \r\n";
     }
    if(CorrectiveAction.trim().toString()=="" ||CorrectiveAction.trim().toString()== null ){
      alertmsg+="Please Enter Corrective Action \r\n";
    }
    // if(CorrectiveAction.trim().length < 50 ){
    //   alertmsg+="Minimum length for Corrective Action is 50 \r\n";
    // }
    if(PreventiveAction.trim().toString()=="" ||PreventiveAction.trim().toString()== null ){
      alertmsg+="Please Enter Preventive Action \r\n";
    }
    // if(PreventiveAction.trim().length < 50 ){
    //   alertmsg+="Minimum length for Preventive Action  is 50 \r\n";
    // }
    if(ProcessChangeImpact.trim().toUpperCase()=="YES" && (ProcessUpdationDate.trim().toString()=="" || ProcessUpdationDate.trim().toString()==null )){
      alertmsg+="Please Select Process Updation Date  \r\n";
    }
    if(ProcessChangeImpact.trim().toUpperCase() !="YES" && (Justification.trim().toString()=="" || Justification.trim().toString()==null )){
      alertmsg+="Please Enter Justification note  \r\n";
    }
    if(TrackingPeriod.trim().toString()=="" || TrackingPeriod.trim().toString()==null ){
      alertmsg+="Please Enter Tracking Period  \r\n";
    }
    
    if(!(TrackingPeriod.trim() ==3 || TrackingPeriod.trim() ==6 || TrackingPeriod.trim() ==9 || TrackingPeriod.trim() ==12)){
      alertmsg+="Tracking Period should be 3,6,9 or 12 Months  \r\n";
    }
    let EdmcMember=TrackingEffectivenessEDMCMember.split(";");
    let isvalEdmcMember=true;
    let isvalDmcMember=true;
    if(TrackingEffectivenessEDMCMember.trim().toString()=="" || TrackingEffectivenessEDMCMember.trim().toString()==null ){
      alertmsg+="Please Enter EDMC Member responsible for tracking effectiveness  \r\n";
    }
    else{
      for (let i = 0; i < EdmcMember.length; i++) {
    
        if(EdmcMember[i].trim()!=""){
        if(!(EdmcMember[i].trim().match(mailformat))){
          isvalEdmcMember=false;     
        }
      }
      else{
        isvalEdmcMember=false;     
      }
    }
    }
    let DmcMember=TrackingDMCMember.split(";");
    if(TrackingDMCMember.trim().toString()=="" || TrackingDMCMember.trim().toString()==null ){
      alertmsg+="Please Enter DMC Member responsible for effectiveness \r\n";
    }
    else{
    for (let i = 0; i < DmcMember.length; i++) {
      if(DmcMember[i].trim()!=""){
      if(!(DmcMember[i].trim().match(mailformat))){
       isvalDmcMember=false; 
      }
 
 }
 else{
  isvalDmcMember=false; 
 }
   }
    }  
if(isvalEdmcMember==false){
alertmsg+="Please Enter Valid EmailId of EDMC Member  \r\n";
}
  if(isvalDmcMember==false){
    alertmsg+="Please Enter Valid EmailId of DMC Member  \r\n";
    }
let valalertmessage=""
   
   
    if(alertmsg.trim() !=""){
      alert(alertmsg);
      return;
    }else{
      let validemail=CheckValidEDMCEmail().then(
        (response) => {           
    
          if(response.toString()!=""){
            alert(response.toString());
            return;
          } 
          else{navigate("/NewSubmit")}
         
        }
      );
      
    }
  }


  const onFileDrop = (e) => {
      
    const newFile = e.target.files[0];
    
    const abc= [...newAttachedFile, newFile];
    let tmpAllowedFileType = process.env.REACT_APP_ATTACHMENT_TYPE;
    const AllowedFileType = tmpAllowedFileType.split(",");
    let isvalid = true;
    let isvalidFormat = false;      
    let Filetype = "";

    Filetype = newFile.name.slice((Math.max(0, newFile.name.lastIndexOf(".")) || Infinity) + 1)

    for (let j = 0; j < AllowedFileType.length; j++) {
        if (Filetype == AllowedFileType[j]) {               
            isvalidFormat = true;
        }           
    }

    if (isvalidFormat != true) {
        alert("Attached file should be of type .doc, .pdf, .xls, .xlsx, .mst, .ppt, .dwg \r\n Files are not saved ");
        isvalidFormat = false;
        return;
    }
    if (newFile.size > 5000000) {
        alert("File " + newFile.name + " is greater than 5 MB \r\n Files are not saved");
        isvalid = false;
        return;
    }
   
    if(isvalid){
    if (newFile) {
        const updatedList = [...fileList, newFile];                      
        setFileList(updatedList); 
        handleAttachmentSubmit(abc);            
       
       setnewAttachedFile([]);
    }
}
};

const fileRemove = (file) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    
    setFileList(updatedList);
  
    MultipleFileLocations.splice(fileList.indexOf(file), 1);
    FileNames.splice(fileList.indexOf(file), 1);
    
    const FileLocations = MultipleFileLocations.map(i => i.s3Location).toString();
    const FileName = FileNames.map(i => i.Fnames).toString();

    sessionStorage.setItem("keys3Location", FileLocations);
    sessionStorage.setItem("keysFileNames", FileName);

  
};

const handleAttachmentSubmit = async (files) => {

    let isvalid = true;
    
    let tmpAllowedFileType = process.env.REACT_APP_ATTACHMENT_TYPE;
    const AllowedFileType = tmpAllowedFileType.split(",");
    
    for (let i = 0; i < files.length; i++) {
        let isvalidFormat = false;
        const tempf = files[i];
       
        let Filetype = "";
        Filetype = files[i].name.slice((Math.max(0, files[i].name.lastIndexOf(".")) || Infinity) + 1)
        
        for (let j = 0; j < AllowedFileType.length; j++) {
            if (Filetype == AllowedFileType[j]) {                    
                isvalidFormat = true;
            }           
        }

        if (isvalidFormat != true) {
            alert("Attached file should be of type .doc, .pdf, .xls, .xlsx, .mst, .ppt, .dwg \r\n Files are not saved ");
            isvalidFormat = false;
            return;
        }
        if (files[i].size > 5000000) {
            alert("File " + files[i].name + " is greater than 5 MB \r\n Files are not saved");
            isvalid = false;
            return;
        }

    }
    if (isvalid == true) {
    
        for (let i = 0; i < files.length; i++) {
         
            const f = files[i];                
            FileNames.push({ Fnames: files[i].name });
            const API_ENDPOINT = process.env.REACT_APP_CHANGEINITIATION_API_URL + "Attachment/filename/"+files[i].name 

            const response = await axios({
                method: 'GET',
                url: API_ENDPOINT
            }).catch((error) => {
                console.log(error);
               
            });
            
                       
            const result = await fetch(response.data.uploadURL, {
                method: 'PUT',
                body: files[i]
            }).catch((error) => {
                console.log(error);
              
            });                
            
            let var2 = response.data.uploadURL;
            // let s3Location = var2.substring(0, var2.indexOf("?"));
            let s3Location=response.data.uploadURL;
                         
            setUploadedFileLocation(s3Location);
            MultipleFileLocations.push({ s3Location: s3Location });
            
        }
      
    }
    
   const FileLocations = MultipleFileLocations.map(i => i.s3Location).toString();
     const FileName = FileNames.map(i => i.Fnames).toString();


     sessionStorage.setItem("keys3Location", FileLocations);
     sessionStorage.setItem("keysFileNames", FileName);

}



  function toggle(e) {

    const e1 = e.target;

    console.log(e1.value)
    setProcessChangeImpact(e1.value);

    if (e1.value == "Yes") {

      document.getElementById('dtProcessUpdationDate').disabled = false;

      document.getElementById('txt').disabled = true;

      document.getElementById('txt').value = "";

    }

    else {

      document.getElementById('dtProcessUpdationDate').disabled = true;

      document.getElementById('txt').disabled = false;

      document.getElementById('yesno').value = "";
    }
  }


  var s = (new Date(ProcessUpdationDate)).toString().split(' ');
  var tosavedate = [s[2], s[1], s[3]].join('-');

  sessionStorage.setItem("keycorrectiveaction", CorrectiveAction)
  sessionStorage.setItem("keypreventiveaction", PreventiveAction)
  sessionStorage.setItem("keyProcessUpdationDate", ProcessUpdationDate)
  sessionStorage.setItem("keyJustification", Justification)
  sessionStorage.setItem("keyTrackingPeriod", TrackingPeriod)
  sessionStorage.setItem("keyTrackingEffectivenessEDMCMember", TrackingEffectivenessEDMCMember)
  sessionStorage.setItem("keyTrackingDMCMember", TrackingDMCMember)
  sessionStorage.setItem("keyProcessChangeImpact", ProcessChangeImpact)
  return (
    <div>
      
      <h5  class="required-field"> Reason For Change (Applicable Analytical Tools can be attached) </h5> (Size per file upto 5 MB max (max 5 files allowed), allowed file types are .doc, .pdf, .xls, .xlsx, .mst, .ppt, .dwg)
      <hr />
      <Row>

<div>
    <input
    id="attachmentbtn"
        type="file"             
        title="Select or Drag Files here"
        onChange={onFileDrop}
        disabled={fileList.length >= 5 ? true : false}

    />
</div>
</Row><br></br> <Row>
<div>
    {fileList.length > 0 ? (
        <div className="files-list">
            {fileList.map((item, index) => (
                <div className="files-list-map" key={index}>
                    <button className="files-report-icon" />
                    <span className="files-note">{item.name}</span>
                    <button
                        className="files-trash-icon"
                        onClick={() => fileRemove(item)}
                    />
                </div>
            ))}
        </div>
    ) : null}
</div>
</Row>
      <h5 class="required-field"> Corrective Action</h5>
      <hr />
      <textarea
        name="paragraph_text" value={CorrectiveAction} cols="136" rows="5" onChange={(e) => setCorrectiveAction(e.target.value)} ></textarea>
      <br />
      <br />
      <h5 class="required-field"> Preventive Action</h5>
      <hr />
      <textarea value={PreventiveAction} name="paragraph_text" cols="136" rows="5" onChange={(e) => setPreventiveAction(e.target.value)} ></textarea>

      <br />
      <br />
      <hr />
      <h5 class="required-field"> Process Change</h5>
      <hr />
      <div class="row">
        <div class="col-3">
          <div className="control">
            <label for="yesno">Will Process address the issue ? </label>

          </div>
        </div>
        <div class="col-1 mb-2">
          <div className="control">

            <select name="yesno"  value={ProcessChangeImpact} id="yesno" onChange={toggle}>
              <option value="No">No</option>
              <option value="Yes">Yes</option>
              <option value="NA">NA</option>
            </select>
          </div>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-4">
          <div className="control">
            <label for="yesno">If Yes, by when will Process be updated ? </label>

          </div>
        </div>
        <div class="col-2 mb-8">
          <div className="control">

            <input id="dtProcessUpdationDate"
              class="input input--bravo pad-1/2 span-1x field-dtProcessUpdationDate is-invalid"
              data-palm="span-1x"
              placeholder="dd/mm/yyyy"
              //    pattern="\d{2}\/\d{2}\/\d{4}*" 
              required pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
              name="payload[dtProcessUpdationDate]"
              type="date" _flx1_12_1="1" _flx1_13_1="1"
              value={ProcessUpdationDate} disabled onChange={(e) => setProcessUpdationDate(e.target.value)} />

          </div>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-4">
          <div className="control">
            <label for="yesno">If No or NA, Kindly annex justification note </label>

          </div>
        </div>
        <div class="col-1 mb-2">
          <textarea id="txt" value={Justification} name="paragraph_text" cols="90" rows="3" onChange={(e) => setJustification(e.target.value)}></textarea>
        </div>
      </div>

      <br />
      <br />
      <hr />
      <h5>Effectiveness Of Preventive Action :</h5>
      <hr />
      <div class="row justify-content-between">
        <div class="col">
          <div className="control">
            <label id="crnumber" class="required-field" > Period for which to be tracked (min 3, 6, 9, 12 months) :</label>

          </div>
        </div>
        <div class="col-8">
          <div className="control">

            <input type="number" value={TrackingPeriod} onChange={(e) => setTrackingPeriod(e.target.value)} ></input>
          </div>
        </div>
      </div>

      <div class="row justify-content-between">
        <div class="col">
          <div className="control">
            <label id="crnumber" class="required-field"> EDMC Member responsible for tracking effectiveness:</label>

          </div>
        </div>
        <div class="col-8">
          <div className="control">

            <input type="email" value={TrackingEffectivenessEDMCMember} placeholder="user@domain.com" onChange={(e) => setTrackingEffectivenessEDMCMember(e.target.value)}></input>
          </div>
        </div>
      </div>

      <div class="row justify-content-between">
        <div class="col">
          <div className="control">
            <label class="required-field" id="crnumber"> DMC Member responsible for effectiveness :</label>

          </div>
        </div>
        <div class="col-8">
          <div className="control">

            <input type="email" value={TrackingDMCMember} placeholder="user@domain.com" onChange={(e) => setTrackingDMCMember(e.target.value)} ></input>
          </div>
        </div>
      </div>
      <hr />
      <br />
      <Button class="btn-primary"
color='#0d6efd'

size="sm"
style={{ float: "left" }}
onClick={() => {
  navigate("/ChangeAnalysisNewForm")
}}
>

Back
</Button>
<Button class="btn-primary"

size="sm"
style={{ float: "right" }}
onClick={chkValidation}
// onClick={() => {


//   navigate("/NewSubmit")

// }}

>

Next
</Button>


    </div>

  )
}



{/* <ChangeAnalysisUploader2 /> */}
export default ChangeAnalysisUploader2












