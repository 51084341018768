/* 
Program Name : Login.jsx
Written By: Snehal Kurhade
Written on: 24 MAY 2022 
System Name: Change Management App
Purpose: Login Page
Type of Program: React Frontend
*/

import { Container, Button, Row, Col, Image, Stack } from "react-bootstrap";
import React from "react";
import home from "../images/Godrej-logo.png"
import product from "../images/Godrej-logo.png"
// import { Google,Facebook } from 'react-bootstrap-icons';
import logo from "../images/godrej-logo-dark.svg"
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

export default function Login({ validUser }) {
    const { instance } = useMsal();
    function handleLogin(instance) {
        instance.loginRedirect(loginRequest).catch(e => {
            console.error(e);
        });
    }
    return (

        <Container bsPrefix="login-container"  >
            <div className="d-flex justify-content-between align-items-center logoHeader">
                <Image src={logo} />
                <span className="headerLink">Godrej Enterprises Group</span>
            </div>
            <div className="container-fluid text-center vh-100 overflow-hidden">
                <div className="row align-items-start d-flex">
                    <div className="col-12 col-md-8 noPadMar bg-primary loginBg"></div>
                    <div className="col-12 col-md-4 loginForm d-flex flex-column">
                        <div className="my-5">
                            <div className="text-start mb-4 godrejPurple">
                                <h1>Change</h1>
                                <h1>Management</h1>
                                <h1>System</h1>
                            </div>

                            <div className="mt-5 ">
                                <Button className="justify-content-center w-100 rounded-pill" variant="success" onClick={() => handleLogin(instance)}>Login</Button>
                            </div>
                        </div>

                        {/* <div class="d-flex footerLinks mt-auto">
                            <a href="#">Copyright @ 2022 </a><span>|</span>
                            <a href="#">Godrej Infotech</a>
                        </div> */}
                    </div>
                </div>
            </div>
        </Container>


        // <Container className="login-container">        
        //     <Row className="d-flex">
        //     <Col className="justify-content-center">
        //         <Container className="Login">
        //         {/* <Row>
        //                 <Col>
        //         <Image className="loginLogo" src={product} />          
        //         </Col>
        //         </Row> */}
        //            <Row><br></br></Row>   <Row><br></br></Row> <Row><br></br></Row> <Row><br></br></Row> <Row><br></br></Row> 
        //             <br></br>
        //             <Row >
        //             <Col></Col>
        //                 <Col>
        //                     <Button variant="success" onClick={() => handleLogin(instance)}><Stack className="justify-content-center" direction="horizontal" gap={2}><Image className="SignInBtn" src={logo} /> Sign in with Godrej</Stack></Button>
        //                 </Col>
        //                 <Col></Col>
        //             </Row>
        //             <Row><br></br></Row> <Row><br></br></Row> <Row><br></br></Row> <Row><br></br></Row> <Row><br></br></Row> <Row><br></br></Row>  <Row><br></br></Row> 
        //             <hr></hr>
        //             <Row>
        //                 <Col>
        //                 <div className="justify-content-center d-flex">Copyright @ 2022 | Godrej Infotech</div>
        //                 </Col>
        //             </Row>


        //         </Container>
        //     </Col>
        //     </Row>

        // </Container>
    )
}