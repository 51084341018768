/* 
Program Name : DashboardofFeasibility.jsx
Written By: Neha Hankare
Written on: 6 MAY 2022 
System Name: Change Management App
Purpose:  Screen to Display details of change Feasibility
Type of Program: React Frontend
*/
import React, { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Tabs from 'react-bootstrap/Tabs';
//import { View, StyleSheet, Text } from 'react-native';
import Tab from 'react-bootstrap/Tab';

//import { v4 as uuidv4 } from 'uuid';
import { Button, Col,  Form, Row, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {getAllRequestNumber} from "../services/ChangeInitiationforFeasibility-service";
import { useState } from "react";
import {  
    getAll,getEdmcDetails
  } from "../services/edmc-service";
   import {
    SubmitDetails, getAll1,
    getChangeInitiationAndApproval,
    getSectionMasterDivision,
    checkPreviousRecord
   } from "../services/cf-service";
 import{
   getlatestFormId
}
  from "../services/FormId-service";
  import {
    getEmailId,
} from "../services/PDASpoc-service";
export default function Dashboard() {
    useEffect(() => {
        fetchEdmc();fetchDivision();  fetchChangeAnalysisNumber(); fetchChangeRequest(); fetchEdmcDate(); fetchDmcDate();  fetchSubmitDate(); //fetchFormId(); fetchChangeAnalysisNumber
      }, []);
      const navigate = useNavigate();
      const [Title, setTitle] = useState("");
      const [WorkType, setWorkType] = useState("");
      const [ProjectName, setProjectName] = useState("");
      const [ProjectNumber, setProjectNumber] = useState("");
      const [Division, setDivision] = useState("");
      const [FeasibilityEDMCMember, setFeasibilityEDMCMember] = useState("");
      const [FeasibilityDmcMember, setFeasibilityDmcMember] = useState("");
      const [FeasibilityEDMCMemberEmail, setFeasibilityEDMCMemberEmail] = useState("");
      const [FeasibilityDmcMemberEmail, setFeasibilityDmcMemberEmail] = useState("");
      const [ChangeRequestNo, setChangeRequestNo] = useState("Select");
      const [ChangeRequestDate, setChangeRequestDate] = useState("");
      const [ExistingWorkPackageDetails, setExistingWorkPackageDetails] = useState("");
      const [ProposedChanges, setProposedChanges] = useState("");
      const [AnticipatedTasksImpactedOwnFunction, setAnticipatedTasksImpactedOwnFunction] = useState("");
      const [AnticipatedTasksImpactedOtherFunction, setAnticipatedTasksImpactedOtherFunction] = useState("");
      const [AdditionalTimeRequiredforDetailedFeasibilityCheck, setAdditionalTimeRequiredforDetailedFeasibilityCheck] = useState("");
      const [EstimatedTimeRequiredforChangeIncludingImpactedTasks, setEstimatedTimeRequiredforChangeIncludingImpactedTasks] = useState("");
      const [EstimatedCostForChangeIncludingImpactedTasks, setEstimatedCostForChangeIncludingImpactedTasks] = useState("");
      const [AnticipatedImpactonQuality, setAnticipatedImpactonQuality] = useState("");
      const [AnticipatedImpactonSafety, setAnticipatedImpactonSafety] = useState("");
      const [AnticipatedImpactoncontractterms, setAnticipatedImpactoncontractterms] = useState("");
      const [AnticipatedImpactonCompliances, setAnticipatedImpactonCompliances] = useState("");
      const [FeasibilityInputGivenBy, setFeasibilityInputGivenBy] = useState("");
      const [FeasibilityInputGivenByEmail, setFeasibilityInputGivenByEmail] = useState("");
      const [FeasibilityInputGivenOn, setFeasibilityInputGivenOn] = useState("");
      const [RemarksIfAny, setRemarksIfAny] = useState("");
      const [EDMCInputAcceptance, setEDMCInputAcceptance] = useState("Select");
      const [EDMCInputAcceptanceBy, setEDMCInputAcceptanceBy] = useState("");
      const [EDMCInputAcceptanceOn, setEDMCInputAcceptanceOn] = useState("");
      const [DMCInputAcceptance, setDMCInputAcceptance] = useState("Select");
      const [DMCInputAcceptanceBy, setDMCInputAcceptanceBy] = useState("");
      const [DMCInputAcceptanceOn, setDMCInputAcceptanceOn] = useState("");
      const [isPending, setIsPending] = useState(false);
      const [value, setValue] = React.useState(0);
      const [EdmcOptions, setEdmcOptions] = useState([]);
      const [EdmcMember, setEdmcMember] = useState("Select");
      const [GodrejConstructionSection, setGodrejConstructionSection] = useState("");
      const [PDASpoc, setPDASpoc] = useState("");
      const [PrevFeasibilityData, setPrevFeasibilityData] = useState("");
      const[previousdataResult,setpreviousdataResult]= useState([]);

      let Tomail="";
      let CCMail="";
      let EmailSubject="";
      let EmailBody="";
      let  PrevFeasibilityDatatest=0;  
      
      let username =localStorage.getItem('UserName');
      let emailID = localStorage.getItem('UserId');
      let ProcessIndication ="SubmittedByInitiator";  
      let pdaspocName ="";       
      let Created="";
       const [ChangeRequestOptions, setChangeRequestOptions] = useState([]);
       const [key, setKey] = useState('Basic');
       const today = new Date();
       const yyyy = today.getFullYear();
       let mm = today.getMonth() + 1; // Months start at 0!
       let dd = today.getDate();
       if (dd < 10) dd = '0' + dd;
       if (mm < 10) mm = '0' + mm;
       const todaysdate = dd + '/' + mm + '/' + yyyy;
      

       const fetchSubmitDate = async () => {
       
        setFeasibilityInputGivenOn(todaysdate);
        setFeasibilityInputGivenBy(username); 
        setFeasibilityInputGivenByEmail(emailID) ;       
    };
    const fetchDivision = async () => {
      
      const SectionMaster = getSectionMasterDivision(emailID).then(
        (response) => {
        
          setDivision(response.data[0].SMTeamName);
        }
      )
    }
    const fetchChangeAnalysisNumber = async () => {
      const resultchangeanalysisnumber = await getAll1();
      
      let canumbers = resultchangeanalysisnumber.map(t => (t.Title).split("-").pop());
        
        let arracan = [];
        for(let i=0; i<canumbers.length; i++){
          arracan.push(canumbers[i].split("-").pop());
        }
         let MaxRevNo = Math.max.apply(null, canumbers);
         
         if (!(isFinite(MaxRevNo)) || MaxRevNo=="-Infinity" ) {
          setTitle(1);
         }
      else{
         setTitle(MaxRevNo + 1);
      }
      document.getElementById("toggle1").disabled = !(document.getElementById("ImpactofChangeswitch").checked);
      document.getElementById("toggle2").disabled = !(document.getElementById("ImpactofChangeswitch").checked);
    }
       const fetchEdmcDate = async () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        const todaysdate = dd + '/' + mm + '/' + yyyy;
     
        setEDMCInputAcceptanceOn(todaysdate);
    };
    const fetchDmcDate = async () => {
      const today = new Date();
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();
      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;
      const todaysdate = dd + '/' + mm + '/' + yyyy;
      
      setDMCInputAcceptanceOn(todaysdate);
  };
      const fetchEdmc = async () => {
         
           const result = await getAll(); 
           
          let EDMC = [];
          EDMC.push({ edmcEmail: "Select" , DMCName: null});
          result.forEach(element => {
            EDMC.push({ edmcEmail: element.EDMCMemberEmail, DMCEmail:element.DMCofEDMC, DMCName:  element.DMCofEDMCName, edmcName: element.EDMCMember});
          })
         
          setEdmcOptions(EDMC);
        };
        // const fetchEdmcData =async() =>{
        //   const EDMCemail = document.getElementById("EDMCemail").value;
        //   const result = await getEdmcDetails(EDMCemail);
          
        // }
        // const fetchChangeRequest12 = async () => {  
          
        //   const ans = await getAll1(); 
          
        // };
        const fetchChangeRequest = async () => {  
          
            const result = await getAllRequestNumber(emailID); 
          
            result.unshift({ChangeRequestNumber: "Select"})
            setChangeRequestOptions(result);
        };
        const chanegEvent = (e) => {

          const arrTAb = ["Basic", "Work Package", "Impact of Change", "Time", "Cost", "Safety", "Compliance", "Submit", "EDMC", "DMC"]
          for (let i = 0; i < arrTAb.length; i++) {
              let tabid = "controlled-tab-example-tab-" + arrTAb[i];
              let tabpaneid = "controlled-tab-example-tabpane-" + arrTAb[i];
          
              if (arrTAb[i] == e) {
  
                  document.getElementById(tabid).removeAttribute('tabIndex');
                  document.getElementById(tabid).classList.add('active');
                  document.getElementById(tabid).ariaSelected = true;
                  document.getElementById(tabpaneid).classList.add('active');
                  document.getElementById(tabpaneid).classList.add('show');
              }
              else {
                
                  document.getElementById(tabid).classList.remove('active');
                  document.getElementById(tabid).ariaSelected = false;
                  document.getElementById(tabid).tabIndex = -1;
                  document.getElementById(tabpaneid).classList.remove('active');
                  document.getElementById(tabpaneid).classList.remove('show');
                  
              }
          }
  
  
      }
     
        const handleToggleButton = (e) => {          
            document.getElementById("toggle1").disabled = !(e.target.checked);
            document.getElementById("toggle2").disabled = !(e.target.checked);
            if(!(e.target.checked)){
              document.getElementById("toggle1").value="";
              document.getElementById("toggle2").value="";
              setAnticipatedTasksImpactedOwnFunction("");
              setAnticipatedTasksImpactedOtherFunction("");              
            }
        }
        const handleSubmit =async (e) => {

        
          const prevRecordResult=await checkPreviousRecord(ChangeRequestNo,Division,username);

          if(prevRecordResult.length===0){
         
          if(!document.getElementById('agree').checked)
            {   
              alert('You must agree to the terms first.');
                return false;
            }
          if(ChangeRequestNo == "Select"){ 
            alert('Select Chnage Request No');
            chanegEvent("Basic");
            return false;
          }
          if(EdmcMember == "Select"){ 
            alert('Select EDMC Member');
            chanegEvent("Basic");
            return false;
          }
          e.preventDefault();
          setIsPending(true);
             Tomail=FeasibilityEDMCMemberEmail;
           EmailSubject="Feasibility Report"
           EmailBody = "Dear Sir / Madam,<br /><br />"
           EmailBody += "A Feasibility Report For ( " + ChangeRequestNo + ") has been initiated by  " + username + " and same is pending for your inputs and necessary action.<br />"
           EmailBody += "Kindly log into the App or Following link to approve / reject / inputs.  <br />"
           EmailBody += '<a href="https://www.changemanagementgc.godrejenterprises.com/"> Click here </a><br /><br />'

           EmailBody += "Regards,<br />"
           EmailBody += "Team Construction.<br /><br />"
           EmailBody += "This is an automated mail, do not reply. ";
         
           Created= todaysdate +" "+ today.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
          const Details = {
            Title,
            WorkType,
            ProjectName,
            ProjectNumber,
            Division,
            FeasibilityEDMCMember,
            FeasibilityDmcMember,
            ChangeRequestNo,
            ChangeRequestDate,
            ExistingWorkPackageDetails,
            ProposedChanges,
            AnticipatedTasksImpactedOwnFunction,
            AnticipatedTasksImpactedOtherFunction,
            AdditionalTimeRequiredforDetailedFeasibilityCheck,
            EstimatedTimeRequiredforChangeIncludingImpactedTasks,
            EstimatedCostForChangeIncludingImpactedTasks,
            AnticipatedImpactonQuality,
            AnticipatedImpactonSafety,
            AnticipatedImpactoncontractterms,
            AnticipatedImpactonCompliances,
            FeasibilityInputGivenBy,
            FeasibilityInputGivenByEmail,
            FeasibilityInputGivenOn,
            RemarksIfAny,
            EDMCInputAcceptance,
            EDMCInputAcceptanceBy,
            EDMCInputAcceptanceOn,
            DMCInputAcceptance,
            DMCInputAcceptanceBy,
            DMCInputAcceptanceOn,
            ProcessIndication,
            sendTo,
             GodrejConstructionSection,
            PDASpoc,
            FeasibilityEDMCMemberEmail,
            FeasibilityDmcMemberEmail,   
            Tomail,
            CCMail,
            EmailSubject,
            EmailBody,
            Created
          };
                 
          SubmitDetails(Details)
            .then((resp) => {         
                        if (resp.Details.status) {
                console.log(resp.Details.Message);
                setIsPending(false);
                window.alert(Title);
                navigate("/feasibilitydashboard");
               }            
            })
            .finally(() => {
              
              setIsPending(false);
            })
            .catch((error) => {
              console.log(error);
            });
            alert("Successfully Submitted Data CFR-" + Title);
            navigate("/feasibilitydashboard");
            setIsPending(false);
          }
          else{
            alert("You have already submitted data for Change Request Number " +ChangeRequestNo +" and Division " + Division);
            navigate("/feasibilitydashboard");
          }
        };
        const getEdmcData= (value) => {          
          EdmcOptions.forEach( (element) => {
            if(element.edmcEmail ==  value){
              setFeasibilityDmcMember(element.DMCName);
              setEDMCInputAcceptanceBy(element.edmcName);
              setDMCInputAcceptanceBy(element.DMCName);
              setFeasibilityEDMCMemberEmail(element.edmcEmail);
              setFeasibilityDmcMemberEmail(element.DMCEmail);
              return;
            } 
          })
        }            

          const fetchPDASPOCMail = async () => {          
          const PDASPOCresult = await getEmailId(pdaspocName);          
          setPDASpoc((PDASPOCresult.map(t => t.TeamMemberEmail)).toString());
      };
           
        const getChangeRequestData = async (value) => {
          ChangeRequestOptions.forEach( (element) => {
            if(element.ChangeRequestNumber ==  value){ 
              
              if(PrevFeasibilityData==0){
              
              setWorkType(element.WorkType);
             
              setProjectName(element.ProjectName);
              setProjectNumber(element.ProjectNumber)
              setChangeRequestDate(element.ChangeRequestDate);
               setGodrejConstructionSection(element.GodrejConstructionSections);
              pdaspocName=element.PDASpocName;
              fetchPDASPOCMail(); 
              return;
            }
            else{
              alert("You have already submitted inputs for" +value  );
            }
          }
        });
        getChangeInitiationAndApproval(value).then(
          (res) => {
            setProposedChanges(res.data[0].Proposed);
            setExistingWorkPackageDetails(res.data[0].Existing);

          }
        ). catch((err) => console.log(err))

      }
      let sendTo = ""
      const submit= (e) => {       
        handleSubmit(e)
      }
      const submitEDMC= (e) => {
        ProcessIndication = "EDMC"
        setDMCInputAcceptance("Yes")
        sendTo="DMC@example.com";
        handleSubmit(e)
      }
      const submitDMC= (e) => {
        ProcessIndication = "DMC"
        sendTo="EDMC@example.com;DMC@example.com;LoginUser@example.com;"
        handleSubmit(e)
      }
  return (
    <div style={{ display: 'block' }}>
     
      <h3 className="form-title">Form No: {"CFR-"+Title}</h3>

      <form className="form">
        <Row>
          <Col>
          <label><h5 className="label-head">Project Name :</h5> {ProjectName} </label>
          </Col>
          <Col></Col>
        </Row>
        <br></br>
      <Tabs id="controlled-tab-example" onSelect={(e) => { chanegEvent(e) }}
      //activeKey={key}
     // onSelect={(k) => setKey(k)}
      className="mb-3">
        <Tab eventKey="Basic" id="Basic" title="Basic">
          <Row>
          <Col>
           <div className="control"  >
            <label>Title :</label>
            <input
              type="text"
              required
              // autoFocus="autofocus"
              value={ "CFR-"+Title}
              disabled
            // hidden
            />
          </div>
          </Col>
          <Col>
          <div className="control">
            <label>Work Type (SI/MI-A/MI-B/DI/OI-A/OI-B/Project): </label>
            <input className="inputText"
              type="text"
              required
              // autoFocus="autofocus"
              value={WorkType}
              onChange={(e) => setWorkType(e.target.value)}
              disabled
            />
          </div>
          </Col>
          </Row>
          <Row>
          <Col>
          <div className="control">
            <label>Initiative / Project Name:</label>
            <input
              type="text"
              required
              value={ProjectName}
              onChange={(e) => setProjectName(e.target.value)}
              disabled
            />
          </div>
          </Col>
          <Col>
          <div className="control">
            <label>Initiative / Project Number:</label>
            <input
              type="text"
              required
              value={ProjectNumber}
              onChange={(e) => setProjectNumber(e.target.value)}
              disabled
            />
          </div>
          </Col>
          </Row>
          <Row>

          <Col>
          <div className="control">
            <label>Section / Division providing Feasibility Input: </label>
            <input
              type="text"
              id="Division"
              disabled
              required
              value={Division}
              onChange={(e) => setDivision(e.target.value)}
            />
          </div>
          </Col>
          <Col>
          <div className="control">
            <label class="required-field"> EDMC Member: </label>
           <Form.Group className="control">
                                {/* <Form.Label>Work Type</Form.Label> */}
                                <Form.Select name="EDMCMember" id="edmcEmail" value={EdmcMember} onChange={(e) => {setEdmcMember(e.target.value); getEdmcData(e.target.value)}} >
                                    {EdmcOptions.map((item, index) => {
                                        return <option value={item.edmcEmail}>{item.edmcEmail}</option>
                                    })}
                                </Form.Select>
                            </Form.Group>
                            </div>
                            </Col>
                            </Row>
                            <Row>
                              <Col>
          <div className="control">
            <label>DMC Member: </label>
            <input
              type="text"
              required
              value={FeasibilityDmcMember}
              onChange={(e) => setFeasibilityDmcMember(e.target.value)}
              disabled
            />
          </div>
          </Col>
          <Col>
          <Form.Group className="control">
                                 <Form.Label class="required-field"> Change Request Number:</Form.Label>
                                <Form.Select name="Change Request Number" id="ChangeRequestNo" value={ChangeRequestNo} onChange={(e) => {setChangeRequestNo(e.target.value); 
                                    getChangeRequestData(e.target.value)
                                  }} >
                                    {ChangeRequestOptions.map((item, index) => {
                                        return <option value={item.ChangeRequestNumber}>{item.ChangeRequestNumber}</option>
                                    })}
                                </Form.Select>
                            </Form.Group>
                            </Col>
                            </Row>
          <div className="control">
            <label>Change Request Date: </label>
            <input
              type="text"
              required
              value={ChangeRequestDate}
              onChange={(e) => setChangeRequestDate(e.target.value)}
              disabled
            />
          </div>
          <Row><br></br></Row>
          <Row>
         

                            <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "right" }} size="sm"
                                onClick={(e) => { chanegEvent("Work Package") }} > Next </Button> </div>
                        </Row>
        </Tab>
        <Tab eventKey="Work Package" id="Work Package" title="Work Package">
        <Row>
          <Col>
          <div className="control">
            <label>Existing Work Package Details: </label>
            <textarea   rows="5" style={{height: "200px"}}
              disabled
              required
              value={ExistingWorkPackageDetails}
              onChange={(e) => setExistingWorkPackageDetails(e.target.value)}
            />
          </div>
          </Col>
          <Col>
          <div className="control">
            <label>Proposed Work Package Details: </label>
            <textarea   rows="5" style={{height: "200px"}}
              required
              disabled
              value={ProposedChanges}
              onChange={(e) => setProposedChanges(e.target.value)}
            />
          </div>
          </Col>
          </Row>
          <Row><br></br></Row>
          <Row>
          <div>
          <div class="float-start m-1"> <Button class="btn-primary" style={{ float: "left" }} size="sm"
                                onClick={(e) => { chanegEvent("Basic") }} > Prev </Button> </div>
                            <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "right" }} size="sm"
                                onClick={(e) => { chanegEvent("Impact of Change") }} > Next </Button> </div>
                       </div>
                        </Row>
        </Tab>
        <Tab eventKey="Impact of Change" id="Impact of Change" title="Impact of Change">
        {/* <label class="switch" >
            <input type="checkbox" onChange={handleToggleButton}/>
            <span className="slider round"></span></label> */}
                 <Row>
            <Col></Col>
            <Col></Col>
             <Col> <Form.Check 
                                    type="switch"
                                    id="ImpactofChangeswitch"
                                    onChange={handleToggleButton}
                                width='3em'
                                /></Col>                               
                                </Row>
                                <br></br>  
                                
          <Row>
            <Col>
          <div className="control">
            <label>Anticipated Tasks Impacted - Own Function: </label>
            <textarea   rows="5" style={{height: "200px"}}
              required
              id="toggle1"
              value={AnticipatedTasksImpactedOwnFunction}
              onChange={(e) => setAnticipatedTasksImpactedOwnFunction(e.target.value)}
            />
          </div>
          </Col>
          <Col>
          <div className="control">
            <label>Anticipated Tasks Impacted - Other Function: </label>
            <textarea   rows="5"
             id="toggle2"
               style={{height: "200px"}}
              required
              value={AnticipatedTasksImpactedOtherFunction}
              onChange={(e) => setAnticipatedTasksImpactedOtherFunction(e.target.value)}
            />
          </div>
          </Col>
          </Row>
          <Row><br></br></Row>
          <Row>
          <div>
          <div class="float-start m-1"> <Button class="btn-primary" style={{ float: "left" }} size="sm"
                                onClick={(e) => { chanegEvent("Work Package") }} > Prev </Button> </div>
                            <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "right" }} size="sm"
                                onClick={(e) => { chanegEvent("Time") }} > Next </Button> </div>
                      </div>
                        </Row>
        </Tab>
        <Tab eventKey="Time" id="Time" title="Time">
        <Row>
          <Col>
          <div className="control">
            <label>Additional Time required for detailed feasibility check (If Applicable): </label>
            <textarea   rows="5" style={{height: "200px"}}
              required
              value={AdditionalTimeRequiredforDetailedFeasibilityCheck}
              onChange={(e) => setAdditionalTimeRequiredforDetailedFeasibilityCheck(e.target.value)}
            />
          </div>
          </Col>
          <Col>
          <div className="control">
            <label>Estimated Time required for change including impacted tasks: </label>
            <textarea   rows="5" style={{height: "200px"}}
              required
              value={EstimatedTimeRequiredforChangeIncludingImpactedTasks}
              onChange={(e) => setEstimatedTimeRequiredforChangeIncludingImpactedTasks(e.target.value)}
            />
          </div>
          </Col>
          </Row>
          <Row><br></br></Row>
          <Row>
          <div>
          <div class="float-start m-1"> <Button class="btn-primary" style={{ float: "left" }} size="sm"
                                onClick={(e) => { chanegEvent("Impact of Change") }} > Prev </Button> </div>
                            <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "right" }} size="sm"
                                onClick={(e) => { chanegEvent("Cost") }} > Next </Button> </div>
                       </div>
                        </Row>
        </Tab>

        <Tab eventKey="Cost" id="Cost" title="Cost">
        <Row>
          <Col>
          <div className="control">
            <label>Estimated Cost for change including impacted tasks: </label>
            <textarea   rows="5" style={{height: "200px"}}
              required
              value={EstimatedCostForChangeIncludingImpactedTasks}
              onChange={(e) => setEstimatedCostForChangeIncludingImpactedTasks(e.target.value)}
            />
          </div>
          </Col>
          <Col>
          <div className="control">
            <label>Anticipated impact on Quality: </label>
            <textarea   rows="5" style={{height: "200px"}}
              required
              value={AnticipatedImpactonQuality}
              onChange={(e) => setAnticipatedImpactonQuality(e.target.value)}
            />
          </div>
          </Col>
          </Row>
          <Row><br></br></Row>
          <Row>
          <div>
          <div class="float-start m-1"> <Button class="btn-primary" style={{ float: "left" }} size="sm"
                                onClick={(e) => { chanegEvent("Time") }} > Prev </Button> </div>
                            <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "right" }} size="sm"
                                onClick={(e) => { chanegEvent("Safety") }} > Next </Button> </div>
                       </div>
                        </Row>
        </Tab>
        <Tab eventKey="Safety" id="Safety" title="Safety">
        <Row>
          <Col>
          <div className="control">
            <label>Anticipated impact on Safety: </label>
            <textarea   rows="5" style={{height: "200px"}}
              required
              value={AnticipatedImpactonSafety}
              onChange={(e) => setAnticipatedImpactonSafety(e.target.value)}
            />
          </div>
          </Col>
          <Col>
          <div className="control">
            <label>Anticipated impact on Contract Terms: </label>
            <textarea   rows="5" style={{height: "200px"}}
              required
              value={AnticipatedImpactoncontractterms}
              onChange={(e) => setAnticipatedImpactoncontractterms(e.target.value)}
            />
          </div>
          </Col>
          </Row>
          <Row><br></br></Row>
          <Row>
          <div>
          <div class="float-start m-1"> <Button class="btn-primary" style={{ float: "left" }} size="sm"
                                onClick={(e) => { chanegEvent("Cost") }} > Prev </Button> </div>

                            <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "right" }} size="sm"
                                onClick={(e) => { chanegEvent("Compliance") }} > Next </Button> </div>
                       </div>
                        </Row>
        </Tab>
        <Tab eventKey="Compliance" id="Compliance" title="Compliance">
        <Row>
          <Col>
          <div className="control">
            <label>Anticipated impact on Compliances: </label>
            <textarea   rows="5" style={{height: "200px"}}
              required
              value={AnticipatedImpactonCompliances}
              onChange={(e) => setAnticipatedImpactonCompliances(e.target.value)}
            />
          </div>
          </Col>
          <Col>
          <div className="control">
            <label>Remarks (If Any): </label>
            <textarea   rows="5" style={{height: "200px" }}
              required
              value={RemarksIfAny}
              onChange={(e) => setRemarksIfAny(e.target.value) }
            />
          </div>
          </Col>
          </Row>
          <Row><br></br></Row>
          <Row>
          <div> 
          <div class="float-start m-1"> <Button class="btn-primary" style={{ float: "left" }} size="sm"
                                onClick={(e) => { chanegEvent("Safety") }} > Prev </Button> </div>

                            <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "right" }} size="sm"
                                onClick={(e) => { chanegEvent("Submit") }} > Next </Button> </div>
                       </div>
                        </Row>
        </Tab>
        <Tab eventKey="Submit" id="Submit" title="Submit">
        <Row>
          <Col>
          <div className="mb-2">
          {/* {FeasibilityInputGivenBy} */}
            <label><h5><div className="label-head">Feasibility input given by :
              (To be filled and signed by person providing input) : </div><br></br>
              <input type="textarea" placeholder="Name" margin= "10px" value={username} onChange={(e) => setFeasibilityInputGivenBy(e.target.value)} disabled/> 
               <input type="textarea" placeholder="Date" margin= "10px" value={FeasibilityInputGivenOn} onChange={(e) => setFeasibilityInputGivenOn(e.target.value)} disabled/></h5></label> <br></br>
               <strong> <font color="E1142E">NOTE: </font></strong><font className="font-msg" color="#810055">Kindly review the form prior to submission.</font><br></br> <strong> <font color="E1142E">Submit </font></strong> <font className="font-msg" color="E1142E">button will send the form and trigger the workflow. 
            Once submitted, information on this form cannot be edited. </font> <br></br>
            <input id="agree" type="checkbox" style={{width: "40px" , height: "40px"}} 
              // value={FeasibilityInputGivenBy}
              // onChange={(e) => setFeasibilityInputGivenBy(e.target.value)}
            /> <label>I agree that information entered is correct to the best
            of my knowledge. In case any change found that will be taken in fresh request form.</label>
            <br></br><br></br>
            <Button
            variant="outline-info"
            text-align= "center"
            disabled={isPending}
            onClick={ submit} 
          >
            {isPending ? "Done" : "Submit"}
          </Button>
          </div>
          </Col>
          </Row>
          <Row><br></br></Row>
          <Row>
          <div>
          <div class="float-start m-1"> <Button class="btn-primary" style={{ float: "left" }} size="sm"
                                onClick={(e) => { chanegEvent("Compliance") }} > Prev </Button> </div>
                            <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "right" }} size="sm"
                                onClick={(e) => { chanegEvent("EDMC") }} > Next </Button> </div>
                                </div>
                        </Row>
        </Tab>
        <Tab eventKey="EDMC" id="EDMC" title="EDMC" >
        {/* <label class="switch">
          <input type="checkbox" />
          <span className="slider round"></span></label> */}
<div className="control">
          <label>EDMC Input Acceptance: </label>
          <select  value={EDMCInputAcceptance} onChange={(e) => setEDMCInputAcceptance(e.target.value)} style={{width: "70px"}} disabled>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
          </select>
        </div>
        <div className="control">
          <label>EDMC Input Acceptance By: </label>
         <input
            type="text"
            required
            value={EDMCInputAcceptanceBy}
            onChange={(e) => setEDMCInputAcceptanceBy(e.target.value)}
            disabled
          />
        </div>
        <div className="control">
          <label>EDMC Input Acceptance On: </label>
          <input
            type="text"
            required
            value={EDMCInputAcceptanceOn}
            onChange={(e) => setEDMCInputAcceptanceOn(e.target.value)}
            disabled
          />
        </div>
        <Button disabled
          variant="outline-info"
          //disabled={isPending}
          onClick={!isPending ? submitEDMC: null}
        >
          {isPending ? "Loading…" : "Submit"}
        </Button>
        <Row><br></br></Row>
        <Row>
          <div>
        <div class="float-start m-1"> <Button class="btn-primary" style={{ float: "left" }} size="sm"
                                onClick={(e) => { chanegEvent("Submit") }} > Prev </Button> </div>
                            <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "right" }} size="sm"
                                onClick={(e) => { chanegEvent("DMC") }} > Next </Button> </div>
                        </div>
                        </Row>
        </Tab>
        <Tab eventKey="DMC" id="DMC" title="DMC" >
        <div className="control" >
          <label>DMC Input Acceptance: </label>
          <select  value={DMCInputAcceptance} onChange={(e) => setDMCInputAcceptance(e.target.value)} style={{width: "70px"}} disabled>
                <option value="Yes" >Yes </option>
                <option value="No">No</option>
          </select>
          
        </div>
        {/* <label class="switch">
          <input type="checkbox" />
          <span className="slider round"></span></label>
        <div className="control">
          <label>DMC Input Acceptance: </label>
          <input
            type="text"
            required
            value={DMCInputAcceptance}
            onChange={(e) => setDMCInputAcceptance(e.target.value)}
          />
        </div> */}
        <div className="control">
          <label>DMC Input Acceptance By: </label>
          <input
            type="text"
            required
            value={DMCInputAcceptanceBy}
            onChange={(e) => setDMCInputAcceptanceBy(e.target.value)}
            //{(element) => setDMCInputAcceptanceBy(element.DMCName)}
            disabled
          />
        </div>
        <div className="control">
          <label>DMC Input Acceptance On: </label>
          <input 
            type="text"
            required
            value={DMCInputAcceptanceOn}
            //onChange={(e) => setDMCInputAcceptanceOn(e.target.value)}
            disabled
          />
        </div>
        <Button disabled
          variant="outline-info"
          //disabled={isPending}
          onClick={!isPending ? submitDMC: null}
        >
          {isPending ? "Loading…" : "Submit"}
        </Button>
        <Row><br></br></Row>
        <Row>
        
        <div class="float-start m-1"> <Button class="btn-primary" style={{ float: "left" }} size="sm"
                                onClick={(e) => { chanegEvent("EDMC") }} > Prev </Button> </div>
                                </Row>
        </Tab>
      </Tabs>
      </form>
    </div>
  );
}