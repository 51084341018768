
/* 
Program Name : HomeScreen.jsx
Written By: Snehal Kurhade
Written on: 17 MAY 2022 
System Name: Change Management App
Purpose: Home Page
Type of Program: React Frontend
*/
import React from 'react';
import "../App_Arun.css";

export default function HomeScreen() {

  return (

    <div className="Mainpara center" >
  <p className="paraheader align-middle col-5 mb-2">Godrej Construction App</p><br></br>
  <p className='subpara'>The Godrej Construction Change Management processes primary focus is to manage changes that impact time and / or cost and / or revenue that impacts any project or initiative that is managed by Godrej Construction. The process covers other Godrej divisions and corporate functions that partner with Godrej Construction for delivery of a project – usually E&E, SSD & Corporate Procurement. GITL as a group entity to G&B is also covered under this process for the projects & initiatives of GC.</p><br></br>
</div>
 
  );
};